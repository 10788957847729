<template>
  <div class="g25-main-icon">
    <svg id="light" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.3 86.37" v-if="variant == 'light'  && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-1" d="M28.34,26.51l-6.21-9.77L.3,13.1v27.38c0,1.08,.62,2.07,1.6,2.54l26.44,12.64,6.32-18.28-6.32-10.88Z"/>
          <path class="cls-3" d="M60.26,57.21l6.21-9.77,21.84-3.63v27.38c0,1.08-.62,2.07-1.6,2.54l-26.44,12.64-6.32-18.28,6.32-10.88Z"/>
          <path class="cls-3" d="M28.3,26.52v29.12l26.39-12.62c.98-.47,1.61-1.46,1.61-2.55V13.13l-28,13.39Z"/>
          <path class="cls-4" d="M29.26,.28c-.77-.37-1.66-.37-2.43,0L0,13.1l28.04,13.41,28.04-13.41L29.26,.28Z"/>
          <g>
            <circle class="cls-2" cx="13.15" cy="35.18" r="2.43"/>
            <circle class="cls-2" cx="5.36" cy="24.63" r="2.31"/>
            <circle class="cls-2" cx="5.36" cy="39.58" r="2.31"/>
            <circle class="cls-2" cx="20.88" cy="30.71" r="2.43"/>
            <circle class="cls-2" cx="20.88" cy="45.72" r="2.43"/>
            <circle class="cls-2" cx="50.72" cy="24.63" r="2.31"/>
          </g>
          <circle cx="50.72" cy="39.58" r="2.31"/>
          <circle class="cls-2" cx="35.21" cy="30.71" r="2.43"/>
          <circle cx="35.21" cy="45.72" r="2.43"/>
          <path class="cls-1" d="M60.3,57.22v29.12l-26.39-12.62c-.98-.47-1.61-1.46-1.61-2.55v-27.34l28,13.39Z"/>
          <path class="cls-4" d="M59.04,30.98c.77-.37,1.66-.37,2.43,0l26.83,12.83-28.04,13.41-28.04-13.41,26.83-12.83Z"/>
          <g>
            <circle class="cls-2" cx="75.16" cy="65.88" r="2.43"/>
            <circle class="cls-2" cx="82.94" cy="70.28" r="2.31"/>
            <circle class="cls-2" cx="67.42" cy="61.41" r="2.43"/>
            <circle class="cls-2" cx="37.58" cy="70.28" r="2.31"/>
            <circle class="cls-2" cx="53.09" cy="61.41" r="2.43"/>
            <ellipse class="cls-2" cx="51.3" cy="48.92" rx="3" ry="1.5"/>
            <ellipse class="cls-2" cx="60.3" cy="43.92" rx="3" ry="1.5"/>
            <ellipse class="cls-2" cx="69.3" cy="38.92" rx="3" ry="1.5"/>
            <ellipse class="cls-2" cx="17.3" cy="7.92" rx="3" ry="1.5"/>
            <ellipse class="cls-2" cx="27.3" cy="11.92" rx="3" ry="1.5"/>
            <ellipse class="cls-2" cx="37.3" cy="17.92" rx="3" ry="1.5"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="dark" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.3 86.37"  v-if="variant == 'dark' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g class="cls-2">
          <path class="cls-1" d="M28.34,26.51l-6.21-9.77L.3,13.1v27.38c0,1.08,.62,2.07,1.6,2.54l26.44,12.64,6.32-18.28-6.32-10.88Z"/>
          <path class="cls-4" d="M60.26,57.21l6.21-9.77,21.84-3.63v27.38c0,1.08-.62,2.07-1.6,2.54l-26.44,12.64-6.32-18.28,6.32-10.88Z"/>
          <path class="cls-4" d="M28.3,26.52v29.12l26.39-12.62c.98-.47,1.61-1.46,1.61-2.55V13.13l-28,13.39Z"/>
          <path class="cls-3" d="M29.26,.28c-.77-.37-1.66-.37-2.43,0L0,13.1l28.04,13.41,28.04-13.41L29.26,.28Z"/>
          <g>
            <circle class="cls-5" cx="13.15" cy="35.18" r="2.43"/>
            <circle class="cls-5" cx="5.36" cy="24.63" r="2.31"/>
            <circle class="cls-5" cx="5.36" cy="39.58" r="2.31"/>
            <circle class="cls-5" cx="20.88" cy="30.71" r="2.43"/>
            <circle class="cls-5" cx="20.88" cy="45.72" r="2.43"/>
            <circle class="cls-5" cx="50.72" cy="24.63" r="2.31"/>
          </g>
          <circle cx="50.72" cy="39.58" r="2.31"/>
          <circle class="cls-5" cx="35.21" cy="30.71" r="2.43"/>
          <circle cx="35.21" cy="45.72" r="2.43"/>
          <path class="cls-1" d="M60.3,57.22v29.12l-26.39-12.62c-.98-.47-1.61-1.46-1.61-2.55v-27.34l28,13.39Z"/>
          <path class="cls-3" d="M59.04,30.98c.77-.37,1.66-.37,2.43,0l26.83,12.83-28.04,13.41-28.04-13.41,26.83-12.83Z"/>
          <g>
            <circle class="cls-5" cx="75.16" cy="65.88" r="2.43"/>
            <circle class="cls-5" cx="82.94" cy="70.28" r="2.31"/>
            <circle class="cls-5" cx="67.42" cy="61.41" r="2.43"/>
            <circle class="cls-5" cx="37.58" cy="70.28" r="2.31"/>
            <circle class="cls-5" cx="53.09" cy="61.41" r="2.43"/>
            <ellipse class="cls-5" cx="51.3" cy="48.92" rx="3" ry="1.5"/>
            <ellipse class="cls-5" cx="60.3" cy="43.92" rx="3" ry="1.5"/>
            <ellipse class="cls-5" cx="69.3" cy="38.92" rx="3" ry="1.5"/>
            <ellipse class="cls-5" cx="17.3" cy="7.92" rx="3" ry="1.5"/>
            <ellipse class="cls-5" cx="27.3" cy="11.92" rx="3" ry="1.5"/>
            <ellipse class="cls-5" cx="37.3" cy="17.92" rx="3" ry="1.5"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="hover" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.3 86.37" v-if="device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-1" d="M28.34,26.51l-6.21-9.77L.3,13.1v27.38c0,1.08,.62,2.07,1.6,2.54l26.44,12.64,6.32-18.28-6.32-10.88Z"/>
          <path class="cls-3" d="M60.26,57.21l6.21-9.77,21.84-3.63v27.38c0,1.08-.62,2.07-1.6,2.54l-26.44,12.64-6.32-18.28,6.32-10.88Z"/>
          <path class="cls-3" d="M28.3,26.52v29.12l26.39-12.62c.98-.47,1.61-1.46,1.61-2.55V13.13l-28,13.39Z"/>
          <path class="cls-2" d="M29.26,.28c-.77-.37-1.66-.37-2.43,0L0,13.1l28.04,13.41,28.04-13.41L29.26,.28Z"/>
          <g>
            <circle class="cls-4" cx="13.15" cy="35.18" r="2.43"/>
            <circle class="cls-4" cx="5.36" cy="24.63" r="2.31"/>
            <circle class="cls-4" cx="5.36" cy="39.58" r="2.31"/>
            <circle class="cls-4" cx="20.88" cy="30.71" r="2.43"/>
            <circle class="cls-4" cx="20.88" cy="45.72" r="2.43"/>
            <circle class="cls-4" cx="50.72" cy="24.63" r="2.31"/>
          </g>
          <circle cx="50.72" cy="39.58" r="2.31"/>
          <circle class="cls-4" cx="35.21" cy="30.71" r="2.43"/>
          <circle cx="35.21" cy="45.72" r="2.43"/>
          <path class="cls-1" d="M60.3,57.22v29.12l-26.39-12.62c-.98-.47-1.61-1.46-1.61-2.55v-27.34l28,13.39Z"/>
          <path class="cls-2" d="M59.04,30.98c.77-.37,1.66-.37,2.43,0l26.83,12.83-28.04,13.41-28.04-13.41,26.83-12.83Z"/>
          <g>
            <circle class="cls-4" cx="75.16" cy="65.88" r="2.43"/>
            <circle class="cls-4" cx="82.94" cy="70.28" r="2.31"/>
            <circle class="cls-4" cx="67.42" cy="61.41" r="2.43"/>
            <circle class="cls-4" cx="37.58" cy="70.28" r="2.31"/>
            <circle class="cls-4" cx="53.09" cy="61.41" r="2.43"/>
            <ellipse class="cls-4" cx="51.3" cy="48.92" rx="3" ry="1.5"/>
            <ellipse class="cls-4" cx="60.3" cy="43.92" rx="3" ry="1.5"/>
            <ellipse class="cls-4" cx="69.3" cy="38.92" rx="3" ry="1.5"/>
            <ellipse class="cls-4" cx="17.3" cy="7.92" rx="3" ry="1.5"/>
            <ellipse class="cls-4" cx="27.3" cy="11.92" rx="3" ry="1.5"/>
            <ellipse class="cls-4" cx="37.3" cy="17.92" rx="3" ry="1.5"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="mobile" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139 139" v-if="device == 'MOBILE'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-4" d="M139,15V124c0,8.25-6.75,15-15,15H15c-8.25,0-15-6.75-15-15V15C0,10.93,1.65,7.21,4.31,4.51c.23-.24,.47-.47,.72-.68C7.68,1.45,11.19,0,15,0H124c8.25,0,15,6.75,15,15Z"/>
          <g class="cls-5">
            <polygon class="cls-2" points="134.81 133.91 123 122.09 123 61 124 61 124 121.68 135.52 133.2 134.81 133.91"/>
            <polygon class="cls-2" points="124 86 118 86 118 75 119 75 119 85 124 85 124 86"/>
            <polygon class="cls-2" points="124.11 76.63 123.47 75.86 129.34 70.97 130.3 67.13 131.27 67.37 130.23 71.53 124.11 76.63"/>
            <path class="cls-2" d="M131.29,98.25c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-2" d="M117.29,97.25c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-2" d="M118.29,76.25c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-2" d="M130.79,67.75c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-2" d="M123.61,61.75c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <polygon class="cls-2" points="123.4 113.1 117 106.71 117 97 118 97 118 106.29 124.1 112.4 123.4 113.1"/>
            <rect class="cls-2" x="122.25" y="100.26" width="9.37" height="1" transform="translate(-34.07 119.27) rotate(-45)"/>
          </g>
          <g class="cls-3">
            <path class="cls-2" d="M17,15.9v61.1h-1V16.32L4.14,4.45c.23-.24,.47-.47,.72-.68l12.14,12.13Z"/>
            <polygon class="cls-2" points="23 63 22 63 22 53 17 53 17 52 23 52 23 63"/>
            <polygon class="cls-2" points="10.27 71.04 9.3 70.79 10.34 66.63 16.46 61.53 17.1 62.3 11.23 67.19 10.27 71.04"/>
            <path class="cls-2" d="M9.28,44.91c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-2" d="M23.28,45.91c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-2" d="M22.28,66.91c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-2" d="M9.78,75.41c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-2" d="M16.96,81.41c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <polygon class="cls-2" points="24 41 23 41 23 31.71 16.9 25.6 17.6 24.9 24 31.29 24 41"/>
            <rect class="cls-2" x="8.95" y="36.9" width="9.37" height="1" transform="translate(-22.45 20.6) rotate(-45)"/>
          </g>
        </g>
        <g>
          <path class="cls-7" d="M61.08,45.25l-3.8-5.99-13.39-2.23v16.79c0,.67,.38,1.27,.98,1.56l16.21,7.75,3.87-11.21-3.87-6.67Z"/>
          <path class="cls-1" d="M80.64,64.07l3.8-5.99,13.39-2.23v16.79c0,.67-.38,1.27-.98,1.56l-16.21,7.75-3.87-11.21,3.87-6.67Z"/>
          <path class="cls-1" d="M61.05,45.26v17.85l16.18-7.74c.6-.29,.98-.89,.98-1.56v-16.76l-17.17,8.21Z"/>
          <path class="cls-2" d="M61.64,29.17c-.47-.23-1.02-.23-1.49,0l-16.45,7.86,17.19,8.22,17.19-8.22-16.45-7.86Z"/>
          <g>
            <circle class="cls-6" cx="51.76" cy="50.57" r="1.49"/>
            <circle class="cls-6" cx="46.99" cy="44.1" r="1.42"/>
            <circle class="cls-6" cx="46.99" cy="53.26" r="1.42"/>
            <circle class="cls-6" cx="56.5" cy="47.83" r="1.49"/>
            <circle class="cls-6" cx="56.5" cy="57.03" r="1.49"/>
            <circle class="cls-6" cx="74.8" cy="44.1" r="1.42"/>
          </g>
          <circle cx="74.8" cy="53.26" r="1.42"/>
          <circle class="cls-6" cx="65.28" cy="47.83" r="1.49"/>
          <circle cx="65.28" cy="57.03" r="1.49"/>
          <path class="cls-7" d="M80.67,64.08v17.85l-16.18-7.74c-.6-.29-.98-.89-.98-1.56v-16.76l17.17,8.21Z"/>
          <path class="cls-2" d="M79.9,47.99c.47-.23,1.02-.23,1.49,0l16.45,7.86-17.19,8.22-17.19-8.22,16.45-7.86Z"/>
          <g>
            <circle class="cls-6" cx="89.78" cy="69.39" r="1.49"/>
            <circle class="cls-6" cx="94.55" cy="72.08" r="1.42"/>
            <circle class="cls-6" cx="85.04" cy="66.65" r="1.49"/>
            <circle class="cls-6" cx="66.74" cy="72.08" r="1.42"/>
            <circle class="cls-6" cx="76.25" cy="66.65" r="1.49"/>
            <ellipse class="cls-6" cx="75.15" cy="58.99" rx="1.84" ry=".92"/>
            <ellipse class="cls-6" cx="80.67" cy="55.93" rx="1.84" ry=".92"/>
            <ellipse class="cls-6" cx="86.19" cy="52.86" rx="1.84" ry=".92"/>
            <ellipse class="cls-6" cx="54.31" cy="33.85" rx="1.84" ry=".92"/>
            <ellipse class="cls-6" cx="60.44" cy="36.31" rx="1.84" ry=".92"/>
            <ellipse class="cls-6" cx="66.57" cy="39.99" rx="1.84" ry=".92"/>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    device: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
#mobile {
  .cls-1 {
    fill: #ddd;
  }

  .cls-2 {
    fill: #fff;
  }

  .cls-3 {
    opacity: .51;
  }

  .cls-4 {
    fill: #f9bb5a;
  }

  .cls-5 {
    opacity: .21;
  }

  .cls-6 {
    fill: #2d2d2d;
  }

  .cls-7 {
    fill: #ededed;
  }
}

  #light {
    .cls-1 {
      fill: #ddd;
    }

    .cls-2 {
      fill: #828282;
    }

    .cls-3 {
      fill: #cecece;
    }

    .cls-4 {
      fill: #f2f2f2;
    }
  }

  #dark {
    .cls-1 {
      fill: #ffb129;
    }

    .cls-2 {
      opacity: .51;
    }

    .cls-3 {
      fill: #f9bb5a;
    }

    .cls-4 {
      fill: #f29a0c;
    }

    .cls-5 {
      fill: #2d2d2d;
    }
  }

  #hover {
    display: none;

    .cls-1 {
      fill: #ffb129;
    }

    .cls-2 {
      fill: #f9bb5a;
    }

    .cls-3 {
      fill: #f29a0c;
    }

    .cls-4 {
      fill: #2d2d2d;
    }
  }

  .g25-menus-card-item:hover {
    #hover {
      display: block;
    }
    #light, #dark {
      display: none;
    }
  }
</style>
