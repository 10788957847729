<template>
  <div>
    <div class="g25-menus-card hidden-g25-dashboard-mobile">
      <div class="g25-menus-card-header">
        <h4>Supplementary</h4>
      </div>
      <div class="g25-menus-card-body">
        <div>
          <div class="g25-menus-card-item mr-1" @click="openDownloadCoordinateModal">
            <supplementary-g-25-down-icon :variant="$store.state.appConfig.layout.skin" device="DESKTOP"/>
            <div class="text">Download Coordinates</div>
          </div>
        </div>
        <div>
          <div class="g25-menus-card-item mr-1" @click="$router.push({ name: 'order-result-g25', params: { type: G25_RESULT_TABS.ENCYCLOPEDIA.code }})">
            <supplementary-sample-db-icon :variant="$store.state.appConfig.layout.skin" device="DESKTOP"/>
            <div class="text">Sample Database</div>
          </div>
        </div>
      </div>
    </div>

    <b-row  class="g25-menus-card hidden-g25-dashboard-desktop">
      <b-col cols="12">
        <div class="g25-menus-card-header mb-1">
          <h4>Supplementary</h4>
        </div>
      </b-col>
      <b-col cols="6" >
        <div class="g25-menus-card-mobile-item" @click="openDownloadCoordinateModal">
          <supplementary-g-25-down-icon :variant="$store.state.appConfig.layout.skin" device="MOBILE"/>
          <div class="text mobile-text">Download Coordinates</div>
        </div>
      </b-col>
      <b-col cols="6" >
        <div class="g25-menus-card-mobile-item" @click="$router.push({ name: 'order-result-g25', params: { type: G25_RESULT_TABS.ENCYCLOPEDIA.code }})">
          <supplementary-sample-db-icon :variant="$store.state.appConfig.layout.skin" device="MOBILE"/>
          <div class="text mobile-text">Sample Database</div>
        </div>
      </b-col>
    </b-row>

    <DownloadCoordinatesModal v-if="modalDownloadCoordinates.showModal" :modalObject="modalDownloadCoordinates"/>
  </div>
</template>

<script>
import { G25_RESULT_TABS } from '@/common/config'
import DownloadCoordinatesModal from '@/views/results/g25/pages/DownloadCoordinatesModal.vue'

import SupplementaryG25DownIcon from '@/layouts/icons/g25/SupplementaryG25DownIcon.vue'
import SupplementarySampleDbIcon from '@/layouts/icons/g25/SupplementarySampleDbIcon.vue'

export default {
  components: {
    DownloadCoordinatesModal,
    SupplementaryG25DownIcon,
    SupplementarySampleDbIcon,
  },
  data() {
    return {
      G25_RESULT_TABS,
      modalDownloadCoordinates: {
        item: null,
        showModal: false,
      },
    }
  },
  methods: {
    openDownloadCoordinateModal() {
      this.modalDownloadCoordinates.showModal = true;
    },
  },
  created() {
  },
}
</script>

<style scoped lang="scss">
.dark-layout .g25-menus-card-header h4{
  color:  var(--lab-g25-supplementary-color) !important;
}
.g25-menus-card-header::before {
  background-color: var(--lab-g25-supplementary-color) !important;
}

.dark-layout .g25-menus-card-item .text {
  color:  var(--lab-g25-supplementary-color) !important;
  opacity: 0.5;
}
.dark-layout .g25-menus-card-item {
  border-color:  #6fceaa7d !important;
}

.g25-menus-card-item:hover {
  transform: scale(1.1);
  border-color:  var(--lab-g25-supplementary-color) !important;
  .text {
    color:  var(--lab-g25-supplementary-color) !important;
    opacity: 1;
  }
}
</style>
