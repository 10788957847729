<template>
  <div class="g25-main-icon">
    <svg id="light" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.89 105.37"  v-if="variant == 'light' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-3" d="M2.65,22.37c-1.32,0-1.32-2,0-2h13.63c1.32,0,1.32,2,0,2H2.65Zm13.59-8H2.72c-1.33,0-1.33-2,0-2h13.53c1.31,0,1.31,2,0,2Z"/>
          <path class="cls-3" d="M14.57,39.37H2.79c-1.33,0-1.33-2,0-2H14.57c1.33,0,1.33,2,0,2Zm.7-8H2.85c-1.31,0-1.31-2,0-2H15.27c1.31,0,1.31,2,0,2Z"/>
          <path class="cls-3" d="M16.25,6.37H2.72c-1.33,0-1.33-2,0-2h13.53c1.31,0,1.31,2,0,2Z"/>
          <path class="cls-3" d="M16.36,47.37H2.58c-1,0-1-2,0-2h13.78c1,0,1,2,0,2Z"/>
          <path class="cls-6" d="M0,1.09c0-1.45,2.18-1.45,2.18,0,0,3.99,3.33,7.14,7.29,7.14,5.17,0,9.47,4.14,9.47,9.35,0,5.17-4.3,9.32-9.47,9.32-3.96,0-7.29,3.18-7.29,7.17,0,3.99,3.33,7.14,7.29,7.14,5.17,0,9.47,4.14,9.47,9.35,0,1.42-2.18,1.42-2.18,0s-3.33-7.17-7.29-7.17C4.3,43.38,0,39.24,0,34.06c0-5.2,4.3-9.35,9.47-9.35,3.96,0,7.29-3.15,7.29-7.14,0-3.99-3.33-7.17-7.29-7.17C4.3,10.41,0,6.26,0,1.09Z"/>
          <path class="cls-1" d="M9.47,10.41c-3.96,0-7.29,3.18-7.29,7.17s3.33,7.14,7.29,7.14c5.17,0,9.47,4.14,9.47,9.35s-4.3,9.32-9.47,9.32c-3.96,0-7.29,3.18-7.29,7.17,0,1.42-2.18,1.42-2.18,0,0-5.2,4.3-9.35,9.47-9.35,3.96,0,7.29-3.15,7.29-7.14s-3.33-7.17-7.29-7.17C4.3,26.89,0,22.75,0,17.58S4.3,8.23,9.47,8.23c3.96,0,7.29-3.15,7.29-7.14,0-1.45,2.18-1.45,2.18,0,0,5.17-4.3,9.32-9.47,9.32Z"/>
        </g>
        <g id="Layer_1-3" data-name="Layer_1">
          <g>
            <path class="cls-7" d="M61.09,105.37c-.5,0-.95-.28-1.07-.78l-2.63-9.7c-.39-1.46,1.79-2.02,2.19-.62l2.58,9.7c.22,.73-.34,1.4-1.07,1.4Z"/>
            <path class="cls-8" d="M71.69,81.37h-6.84c-1.46-.27-1.46-2.51,0-2h6.84c1.46-.51,1.46,1.73,0,2Z"/>
            <path class="cls-7" d="M26.9,105.37c-.73,0-1.29-.67-1.12-1.4l2.63-9.7c.39-1.4,2.52-.84,2.13,.62l-2.58,9.7c-.11,.5-.56,.78-1.07,.78Z"/>
            <path class="cls-7" d="M70.17,73.37h-7.06c-1.46-.17-1.46-2.42,0-2h7.06c1.46-.42,1.46,1.83,0,2Zm-17.71,0h-4.93c-1.46-.17-1.46-2.42,0-2h4.93c1.46-.42,1.46,1.83,0,2Zm-12.05,0h-4.88c-1.51-.17-1.51-2.42,0-2h4.88c1.51-.42,1.51,1.83,0,2Zm-15.58,0h-7.01c-1.46-.17-1.46-2.42,0-2h7.01c1.51-.42,1.51,1.83,0,2Z"/>
            <path class="cls-7" d="M71.69,83.96c-.67,0-1.12-.5-1.15-1.12v-5.66c.03-1.46,2.27-1.46,2,0v5.66c.27,.62-.23,1.12-.85,1.12Z"/>
            <path class="cls-7" d="M48.43,25.45c-.61,0-10.89,.39-10.89-1.11v-8.2c0-3.11,2.23-5.89,5.32-6.25,3.64-.42,6.68,2.44,6.68,5.99v8.46c0,.61-.5,1.11-1.11,1.11Z"/>
          </g>
          <path class="cls-2" d="M29.48,95.73c-6.17-1.35-9.81-7.34-8.24-13.23l10.15-37.78c.39-1.46,2.58-.9,2.19,.56l-.17,.56c.9,1.63,3.31,5.61,6.11,6.95,.39-1.4,2.52-.84,2.19,.62l-11.15,41.48c-.11,.45-.56,.84-1.07,.84Z"/>
          <path class="cls-2" d="M58.51,95.73c-.5,0-.95-.39-1.12-.84l-11.1-41.48c-.34-1.29,1.29-1.85,1.96-1.01,2.75-1.68,5.21-4.48,6.45-6.05l-.28-1.07c-.39-1.46,1.79-2.02,2.13-.56l10.15,37.78c1.57,5.77-1.79,11.49-7.9,13.17-.11,0-.22,.06-.28,.06Z"/>
          <path class="cls-4" d="M43.98,54.37h-.95c-6.9,0-12.5-5.6-12.5-12.5v-15.5c0-1.61,1.34-3,3.03-3h19.95c1.68,0,3.03,1.39,3.03,3v15.61c0,6.83-5.6,12.39-12.55,12.39Z"/>
          <path class="cls-7" d="M44,45.4c-3.53,0-6.45-2.86-6.45-6.45,0-3.53,2.91-6.39,6.45-6.39s6.39,2.86,6.39,6.39c0,3.59-2.86,6.45-6.39,6.45Z"/>
          <path class="cls-5" d="M44,76.73c-6.17,0-6.17-9.36,0-9.36,6.11,0,6.11,9.36,0,9.36Z"/>
        </g>
      </g>
    </svg>

    <svg id="dark" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.89 105.37" v-if="variant == 'dark' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g class="cls-1">
          <g>
            <path class="cls-6" d="M2.65,22.37c-1.32,0-1.32-2,0-2h13.63c1.32,0,1.32,2,0,2H2.65Zm13.59-8H2.72c-1.33,0-1.33-2,0-2h13.53c1.31,0,1.31,2,0,2Z"/>
            <path class="cls-6" d="M14.57,39.37H2.79c-1.33,0-1.33-2,0-2H14.57c1.33,0,1.33,2,0,2Zm.7-8H2.85c-1.31,0-1.31-2,0-2H15.27c1.31,0,1.31,2,0,2Z"/>
            <path class="cls-6" d="M16.25,6.37H2.72c-1.33,0-1.33-2,0-2h13.53c1.31,0,1.31,2,0,2Z"/>
            <path class="cls-6" d="M16.36,47.37H2.58c-1,0-1-2,0-2h13.78c1,0,1,2,0,2Z"/>
            <path class="cls-3" d="M0,1.09C0-.36,2.18-.36,2.18,1.09c0,3.99,3.33,7.14,7.29,7.14,5.17,0,9.47,4.14,9.47,9.35s-4.3,9.32-9.47,9.32c-3.96,0-7.29,3.18-7.29,7.17s3.33,7.14,7.29,7.14c5.17,0,9.47,4.14,9.47,9.35,0,1.42-2.18,1.42-2.18,0,0-3.99-3.33-7.17-7.29-7.17C4.3,43.38,0,39.24,0,34.06c0-5.2,4.3-9.35,9.47-9.35,3.96,0,7.29-3.15,7.29-7.14,0-3.99-3.33-7.17-7.29-7.17C4.3,10.41,0,6.26,0,1.09Z"/>
            <path class="cls-5" d="M9.47,10.41c-3.96,0-7.29,3.18-7.29,7.17s3.33,7.14,7.29,7.14c5.17,0,9.47,4.14,9.47,9.35s-4.3,9.32-9.47,9.32c-3.96,0-7.29,3.18-7.29,7.17,0,1.42-2.18,1.42-2.18,0,0-5.2,4.3-9.35,9.47-9.35,3.96,0,7.29-3.15,7.29-7.14s-3.33-7.17-7.29-7.17C4.3,26.89,0,22.75,0,17.58S4.3,8.23,9.47,8.23c3.96,0,7.29-3.15,7.29-7.14,0-1.45,2.18-1.45,2.18,0,0,5.17-4.3,9.32-9.47,9.32Z"/>
          </g>
          <g id="Layer_1-3" data-name="Layer_1">
            <g>
              <path class="cls-3" d="M61.09,105.37c-.5,0-.95-.28-1.07-.78l-2.63-9.7c-.39-1.46,1.79-2.02,2.19-.62l2.58,9.7c.22,.73-.34,1.4-1.07,1.4Z"/>
              <path class="cls-3" d="M71.69,81.37h-6.84c-1.46-.27-1.46-2.51,0-2h6.84c1.46-.51,1.46,1.73,0,2Z"/>
              <path class="cls-3" d="M26.9,105.37c-.73,0-1.29-.67-1.12-1.4l2.63-9.7c.39-1.4,2.52-.84,2.13,.62l-2.58,9.7c-.11,.5-.56,.78-1.07,.78Z"/>
              <path class="cls-3" d="M70.17,73.37h-7.06c-1.46-.17-1.46-2.42,0-2h7.06c1.46-.42,1.46,1.83,0,2Zm-17.71,0h-4.93c-1.46-.17-1.46-2.42,0-2h4.93c1.46-.42,1.46,1.83,0,2Zm-12.05,0h-4.88c-1.51-.17-1.51-2.42,0-2h4.88c1.51-.42,1.51,1.83,0,2Zm-15.58,0h-7.01c-1.46-.17-1.46-2.42,0-2h7.01c1.51-.42,1.51,1.83,0,2Z"/>
              <path class="cls-3" d="M71.69,83.96c-.67,0-1.12-.5-1.41-1.12v-5.66c.29-1.46,2.54-1.46,2,0v5.66c.54,.62,.03,1.12-.59,1.12Z"/>
              <path class="cls-4" d="M49.17,25.45c-.61,0-10.89,.39-10.89-1.11V15.88c0-3.56,3.11-6.41,6.75-5.98,3.05,.36,5.25,3.12,5.25,6.19v8.26c0,.61-.5,1.11-1.11,1.11Z"/>
            </g>
            <path class="cls-2" d="M29.48,95.73c-6.17-1.35-9.81-7.34-8.24-13.23l10.15-37.78c.39-1.46,2.58-.9,2.19,.56l-.17,.56c.9,1.63,3.31,5.61,6.11,6.95,.39-1.4,2.52-.84,2.19,.62l-11.15,41.48c-.11,.45-.56,.84-1.07,.84Z"/>
            <path class="cls-2" d="M58.51,95.73c-.5,0-.95-.39-1.12-.84l-11.1-41.48c-.34-1.29,1.29-1.85,1.96-1.01,2.75-1.68,5.21-4.48,6.45-6.05l-.28-1.07c-.39-1.46,1.79-2.02,2.13-.56l10.15,37.78c1.57,5.77-1.79,11.49-7.9,13.17-.11,0-.22,.06-.28,.06Z"/>
            <path class="cls-6" d="M44.72,54.37h-.95c-6.9,0-12.5-5.6-12.5-12.5v-15.5c0-1.61,1.34-3,3.03-3h19.95c1.68,0,3.03,1.39,3.03,3v15.61c0,6.83-5.6,12.39-12.55,12.39Z"/>
            <path class="cls-4" d="M44,45.4c-3.53,0-6.45-2.86-6.45-6.45,0-3.53,2.91-6.39,6.45-6.39s6.39,2.86,6.39,6.39c0,3.59-2.86,6.45-6.39,6.45Z"/>
            <path class="cls-4" d="M44,76.73c-6.17,0-6.17-9.36,0-9.36s6.11,9.36,0,9.36Z"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="mobile" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139 139" v-if="device == 'MOBILE'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-8" d="M0,15V124c0,8.25,6.75,15,15,15H124c8.25,0,15-6.75,15-15V15c0-4.07-1.65-7.79-4.31-10.49-.23-.24-.47-.47-.72-.68C131.32,1.45,127.81,0,124,0H15C6.75,0,0,6.75,0,15Z"/>
          <g class="cls-7">
            <polygon class="cls-1" points="4.19 133.91 16 122.09 16 61 15 61 15 121.68 3.48 133.2 4.19 133.91"/>
            <polygon class="cls-1" points="16 86 22 86 22 76 21 76 21 85 16 85 16 86"/>
            <polygon class="cls-1" points="15.47 76.63 16.11 75.86 10.23 70.97 9.27 67.13 8.3 67.37 9.34 71.53 15.47 76.63"/>
            <path class="cls-1" d="M5.79,95.75c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <path class="cls-1" d="M19.79,94.75c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-1" d="M18.79,73.75c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-1" d="M6.29,65.25c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <path class="cls-1" d="M13.47,59.25c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <polygon class="cls-1" points="16.6 113.1 23 106.71 23 97 22 97 22 106.29 15.9 112.4 16.6 113.1"/>
            <rect class="cls-1" x="12.15" y="96.08" width="1" height="9.37" transform="translate(-67.54 38.46) rotate(-45)"/>
          </g>
          <g class="cls-3">
            <path class="cls-1" d="M122,15.9v61.1s1,0,1,0V16.32s11.86-11.87,11.86-11.87c-.23-.24-.47-.47-.72-.68l-12.14,12.13Z"/>
            <polygon class="cls-1" points="117 62 118 62 118 53 123 53 123 52 117 52 117 62"/>
            <polygon class="cls-1" points="129.31 71.04 130.28 70.79 129.24 66.63 123.12 61.53 122.48 62.3 128.35 67.19 129.31 71.04"/>
            <path class="cls-1" d="M127.8,42.41c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-1" d="M113.8,43.41c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <path class="cls-1" d="M114.8,64.41c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-1" d="M127.3,72.91c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-1" d="M120.12,78.91c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <polygon class="cls-1" points="116 41 117 41 117 31.71 123.1 25.6 122.4 24.9 116 31.29 116 41"/>
            <rect class="cls-1" x="125.44" y="32.71" width="1" height="9.37" transform="translate(10.44 100.01) rotate(-45)"/>
          </g>
        </g>
        <g>
          <g>
            <path class="cls-2" d="M45.6,34.97c-.88,0-.88-1.33,0-1.33h9.04c.88,0,.88,1.33,0,1.33h-9.04Zm9.01-5.3h-8.97c-.88,0-.88-1.33,0-1.33h8.97c.87,0,.87,1.33,0,1.33Z"/>
            <path class="cls-2" d="M53.5,46.25h-7.81c-.88,0-.88-1.33,0-1.33h7.81c.88,0,.88,1.33,0,1.33Zm.46-5.3h-8.23c-.87,0-.87-1.33,0-1.33h8.23c.87,0,.87,1.33,0,1.33Z"/>
            <path class="cls-2" d="M54.61,24.37h-8.97c-.88,0-.88-1.33,0-1.33h8.97c.87,0,.87,1.33,0,1.33Z"/>
            <path class="cls-2" d="M54.68,51.55h-9.13c-.66,0-.66-1.33,0-1.33h9.13c.66,0,.66,1.33,0,1.33Z"/>
            <path class="cls-5" d="M43.84,20.86c0-.96,1.44-.96,1.44,0,0,2.65,2.21,4.73,4.83,4.73,3.43,0,6.28,2.75,6.28,6.2s-2.85,6.18-6.28,6.18c-2.63,0-4.83,2.11-4.83,4.75s2.21,4.73,4.83,4.73c3.43,0,6.28,2.75,6.28,6.2,0,.94-1.44,.94-1.44,0,0-2.65-2.21-4.75-4.83-4.75-3.43,0-6.28-2.75-6.28-6.18s2.85-6.2,6.28-6.2c2.63,0,4.83-2.09,4.83-4.73,0-2.65-2.21-4.75-4.83-4.75-3.43,0-6.28-2.75-6.28-6.18Z"/>
            <path class="cls-4" d="M50.12,27.04c-2.63,0-4.83,2.11-4.83,4.75s2.21,4.73,4.83,4.73c3.43,0,6.28,2.75,6.28,6.2,0,3.43-2.85,6.18-6.28,6.18-2.63,0-4.83,2.11-4.83,4.75,0,.94-1.44,.94-1.44,0,0-3.45,2.85-6.2,6.28-6.2,2.63,0,4.83-2.09,4.83-4.73s-2.21-4.75-4.83-4.75c-3.43,0-6.28-2.75-6.28-6.18,0-3.45,2.85-6.2,6.28-6.2,2.63,0,4.83-2.09,4.83-4.73,0-.96,1.44-.96,1.44,0,0,3.43-2.85,6.18-6.28,6.18Z"/>
          </g>
          <g id="Layer_1-3" data-name="Layer_1">
            <g>
              <path class="cls-6" d="M84.34,90c-.33,0-.63-.19-.71-.52l-1.75-6.43c-.26-.97,1.19-1.34,1.45-.41l1.71,6.43c.15,.48-.22,.93-.71,.93Z"/>
              <path class="cls-6" d="M91.36,74.09h-4.53c-.97-.18-.97-1.67,0-1.33h4.53c.97-.34,.97,1.15,0,1.33Z"/>
              <path class="cls-6" d="M61.67,90c-.48,0-.85-.45-.74-.93l1.75-6.43c.26-.93,1.67-.56,1.41,.41l-1.71,6.43c-.07,.33-.37,.52-.71,.52Z"/>
              <path class="cls-6" d="M90.36,68.79h-4.68c-.97-.12-.97-1.6,0-1.33h4.68c.97-.28,.97,1.21,0,1.33Zm-11.74,0h-3.27c-.97-.12-.97-1.6,0-1.33h3.27c.97-.28,.97,1.21,0,1.33Zm-7.99,0h-3.23c-1-.12-1-1.6,0-1.33h3.23c1-.28,1,1.21,0,1.33Zm-10.33,0h-4.65c-.97-.12-.97-1.6,0-1.33h4.65c1-.28,1,1.21,0,1.33Z"/>
              <path class="cls-6" d="M91.36,75.8c-.45,0-.74-.33-.94-.74v-3.75c.19-.97,1.68-.97,1.33,0v3.75c.35,.41,.02,.74-.39,.74Z"/>
              <path class="cls-6" d="M76.43,37.01c-.4,0-7.22,.26-7.22-.73v-5.61c0-2.36,2.06-4.25,4.48-3.97,2.02,.24,3.48,2.07,3.48,4.1v5.48c0,.4-.33,.73-.73,.73Z"/>
            </g>
            <path class="cls-4" d="M63.38,83.61c-4.09-.89-6.5-4.87-5.46-8.77l6.73-25.05c.26-.97,1.71-.59,1.45,.37l-.11,.37c.59,1.08,2.19,3.72,4.05,4.61,.26-.93,1.67-.56,1.45,.41l-7.4,27.5c-.07,.3-.37,.56-.71,.56Z"/>
            <path class="cls-4" d="M82.63,83.61c-.33,0-.63-.26-.74-.56l-7.36-27.5c-.22-.85,.85-1.23,1.3-.67,1.82-1.11,3.46-2.97,4.27-4.01l-.19-.71c-.26-.97,1.19-1.34,1.41-.37l6.73,25.05c1.04,3.83-1.19,7.62-5.24,8.73-.07,0-.15,.04-.19,.04Z"/>
            <path class="cls-2" d="M73.48,56.19h-.63c-4.58,0-8.29-3.71-8.29-8.29v-10.28c0-1.07,.89-1.99,2.01-1.99h13.22c1.11,0,2.01,.92,2.01,1.99v10.35c0,4.53-3.71,8.21-8.32,8.21Z"/>
            <path class="cls-5" d="M73,50.24c-2.34,0-4.27-1.9-4.27-4.27s1.93-4.24,4.27-4.24,4.24,1.9,4.24,4.24c0,2.38-1.9,4.27-4.24,4.27Z"/>
            <path class="cls-5" d="M73,71.01c-4.09,0-4.09-6.21,0-6.21s4.05,6.21,0,6.21Z"/>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    device: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.g25-main-icon #light, .g25-main-icon #dark  {
  height: 80px;
}

#mobile {
  .cls-1, .cls-2 {
    fill: #fff;
  }

  .cls-3 {
    opacity: .51;
  }

  .cls-2, .cls-4, .cls-5, .cls-6 {
    fill-rule: evenodd;
  }

  .cls-4 {
    fill: #2d2d2d;
  }

  .cls-5 {
    fill: #ddd;
  }

  .cls-7 {
    opacity: .21;
  }

  .cls-8 {
    fill: #fc8db0;
  }

  .cls-6 {
    fill: #ededed;
  }
}

#light {
  .cls-1 {
    fill: #828282;
  }

  .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8 {
    fill-rule: evenodd;
  }

  .cls-2 {
    fill: #8b8b8b;
  }

  .cls-3 {
    fill: #ddd;
  }

  .cls-4 {
    fill: #e7e7e8;
  }

  .cls-5 {
    fill: #d6d6d6;
  }

  .cls-6 {
    fill: #cecece;
  }

  .cls-7, .cls-8 {
    fill: #c7c7c7;
  }

  .cls-8 {
    stroke: #c7c7c7;
    stroke-miterlimit: 10;
    stroke-width: .22px;
  }
}

#dark {
  .cls-1 {
    opacity: .51;
  }

  .cls-2 {
    fill: #1d2328;
    stroke: #fc8db0;
    stroke-miterlimit: 10;
  }

  .cls-2, .cls-3, .cls-4, .cls-5, .cls-6 {
    fill-rule: evenodd;
  }

  .cls-3 {
    fill: #f779ac;
  }

  .cls-4 {
    fill: #f94b8a;
  }

  .cls-5 {
    fill: #dd5794;
  }

  .cls-6 {
    fill: #fc8db0;
  }
}

.g25-menus-card-item:hover {
  #light {
    .cls-1 {
     fill: #f779ac;
    }

    .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8 {
      fill-rule: evenodd;
    }

    .cls-2 {
      fill: #2D2D2D;
    }

    .cls-3 {
      fill: #f779ac;
    }

    .cls-4 {
      fill: #f779ac;
    }

    .cls-5 {
      fill: #f94b8a;
    }

    .cls-6 {
      fill: #2D2D2D;
    }

    .cls-7, .cls-8 {
      fill: #f94b8a;
    }

    .cls-8 {
      stroke: #c7c7c7;
      stroke-miterlimit: 10;
      stroke-width: .22px;
    }
  }
  #dark {
    .cls-1 {
      opacity: 1;
    }
  }
}
</style>
