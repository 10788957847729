<template>
  <div class="g25-main-icon">
    <svg id="light" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.3 86.37" v-if="variant == 'light' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-1" d="M60.34,26.51l6.21-9.77,21.84-3.63v27.38c0,1.08-.62,2.07-1.6,2.54l-26.44,12.64-6.32-18.28,6.32-10.88Z"/>
          <path class="cls-3" d="M28.42,57.21l-6.21-9.77L.38,43.8v27.38c0,1.08,.62,2.07,1.6,2.54l26.44,12.64,6.32-18.28-6.32-10.88Z"/>
          <path class="cls-3" d="M60.38,26.52v29.12s-26.39-12.62-26.39-12.62c-.98-.47-1.61-1.46-1.61-2.55V13.13s28,13.39,28,13.39Z"/>
          <path class="cls-4" d="M59.04,.28c.77-.37,1.66-.37,2.43,0l26.83,12.83-28.04,13.41-28.04-13.41L59.04,.28Z"/>
          <g>
            <circle class="cls-2" cx="75.15" cy="35.18" r="2.43"/>
            <circle class="cls-2" cx="82.94" cy="24.63" r="2.31"/>
            <circle class="cls-2" cx="82.94" cy="39.58" r="2.31"/>
            <circle class="cls-2" cx="67.42" cy="30.71" r="2.43"/>
            <circle class="cls-2" cx="67.42" cy="45.72" r="2.43"/>
            <circle class="cls-2" cx="37.58" cy="24.63" r="2.31"/>
          </g>
          <circle cx="37.58" cy="39.58" r="2.31"/>
          <circle class="cls-2" cx="53.09" cy="30.71" r="2.43"/>
          <circle cx="53.09" cy="45.72" r="2.43"/>
          <path class="cls-1" d="M28.38,57.22v29.12s26.39-12.62,26.39-12.62c.98-.47,1.61-1.46,1.61-2.55v-27.34s-28,13.39-28,13.39Z"/>
          <path class="cls-4" d="M29.26,30.98c-.77-.37-1.66-.37-2.43,0L0,43.8l28.04,13.41,28.04-13.41-26.83-12.83Z"/>
          <g>
            <circle class="cls-2" cx="13.15" cy="65.88" r="2.43"/>
            <circle class="cls-2" cx="5.36" cy="70.28" r="2.31"/>
            <circle class="cls-2" cx="20.88" cy="61.41" r="2.43"/>
            <circle class="cls-2" cx="50.72" cy="70.28" r="2.31"/>
            <circle class="cls-2" cx="35.21" cy="61.41" r="2.43"/>
            <ellipse class="cls-2" cx="37.38" cy="48.92" rx="3" ry="1.5"/>
            <ellipse class="cls-2" cx="28.38" cy="43.92" rx="3" ry="1.5"/>
            <ellipse class="cls-2" cx="19.38" cy="38.92" rx="3" ry="1.5"/>
            <ellipse class="cls-2" cx="70.38" cy="7.92" rx="3" ry="1.5"/>
            <ellipse class="cls-2" cx="61.38" cy="11.92" rx="3" ry="1.5"/>
            <ellipse class="cls-2" cx="50.38" cy="17.92" rx="3" ry="1.5"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="dark" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.3 86.37"  v-if="variant == 'dark' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g class="cls-2">
          <path class="cls-1" d="M59.96,26.51l6.21-9.77,21.84-3.63v27.38c0,1.08-.62,2.07-1.6,2.54l-26.44,12.64-6.32-18.28,6.32-10.88Z"/>
          <path class="cls-4" d="M28.04,57.21l-6.21-9.77L0,43.8v27.38c0,1.08,.62,2.07,1.6,2.54l26.44,12.64,6.32-18.28-6.32-10.88Z"/>
          <path class="cls-4" d="M60,26.52v29.12s-26.39-12.62-26.39-12.62c-.98-.47-1.61-1.46-1.61-2.55V13.13s28,13.39,28,13.39Z"/>
          <path class="cls-3" d="M59.04,.28c.77-.37,1.66-.37,2.43,0l26.83,12.83-28.04,13.41-28.04-13.41L59.04,.28Z"/>
          <g>
            <circle class="cls-5" cx="75.15" cy="35.18" r="2.43"/>
            <circle class="cls-5" cx="82.94" cy="24.63" r="2.31"/>
            <circle class="cls-5" cx="82.94" cy="39.58" r="2.31"/>
            <circle class="cls-5" cx="67.42" cy="30.71" r="2.43"/>
            <circle class="cls-5" cx="67.42" cy="45.72" r="2.43"/>
            <circle class="cls-5" cx="37.58" cy="24.63" r="2.31"/>
          </g>
          <circle cx="37.58" cy="39.58" r="2.31"/>
          <circle class="cls-5" cx="53.09" cy="30.71" r="2.43"/>
          <circle cx="53.09" cy="45.72" r="2.43"/>
          <path class="cls-1" d="M28,57.22v29.12s26.39-12.62,26.39-12.62c.98-.47,1.61-1.46,1.61-2.55v-27.34s-28,13.39-28,13.39Z"/>
          <path class="cls-3" d="M29.26,30.98c-.77-.37-1.66-.37-2.43,0L0,43.8l28.04,13.41,28.04-13.41-26.83-12.83Z"/>
          <g>
            <circle class="cls-5" cx="13.15" cy="65.88" r="2.43"/>
            <circle class="cls-5" cx="5.36" cy="70.28" r="2.31"/>
            <circle class="cls-5" cx="20.88" cy="61.41" r="2.43"/>
            <circle class="cls-5" cx="50.72" cy="70.28" r="2.31"/>
            <circle class="cls-5" cx="35.21" cy="61.41" r="2.43"/>
            <ellipse class="cls-5" cx="37" cy="48.92" rx="3" ry="1.5"/>
            <ellipse class="cls-5" cx="28" cy="43.92" rx="3" ry="1.5"/>
            <ellipse class="cls-5" cx="19" cy="38.92" rx="3" ry="1.5"/>
            <ellipse class="cls-5" cx="71" cy="7.92" rx="3" ry="1.5"/>
            <ellipse class="cls-5" cx="61" cy="11.92" rx="3" ry="1.5"/>
            <ellipse class="cls-5" cx="51" cy="17.92" rx="3" ry="1.5"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="hover" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.3 86.37" v-if="device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-1" d="M59.96,26.51l6.21-9.77,21.84-3.63v27.38c0,1.08-.62,2.07-1.6,2.54l-26.44,12.64-6.32-18.28,6.32-10.88Z"/>
          <path class="cls-3" d="M28.04,57.21l-6.21-9.77L0,43.8v27.38c0,1.08,.62,2.07,1.6,2.54l26.44,12.64,6.32-18.28-6.32-10.88Z"/>
          <path class="cls-3" d="M60,26.52v29.12s-26.39-12.62-26.39-12.62c-.98-.47-1.61-1.46-1.61-2.55V13.13s28,13.39,28,13.39Z"/>
          <path class="cls-2" d="M59.04,.28c.77-.37,1.66-.37,2.43,0l26.83,12.83-28.04,13.41-28.04-13.41L59.04,.28Z"/>
          <g>
            <circle class="cls-4" cx="75.15" cy="35.18" r="2.43"/>
            <circle class="cls-4" cx="82.94" cy="24.63" r="2.31"/>
            <circle class="cls-4" cx="82.94" cy="39.58" r="2.31"/>
            <circle class="cls-4" cx="67.42" cy="30.71" r="2.43"/>
            <circle class="cls-4" cx="67.42" cy="45.72" r="2.43"/>
            <circle class="cls-4" cx="37.58" cy="24.63" r="2.31"/>
          </g>
          <circle cx="37.58" cy="39.58" r="2.31"/>
          <circle class="cls-4" cx="53.09" cy="30.71" r="2.43"/>
          <circle cx="53.09" cy="45.72" r="2.43"/>
          <path class="cls-1" d="M28,57.22v29.12s26.39-12.62,26.39-12.62c.98-.47,1.61-1.46,1.61-2.55v-27.34s-28,13.39-28,13.39Z"/>
          <path class="cls-2" d="M29.26,30.98c-.77-.37-1.66-.37-2.43,0L0,43.8l28.04,13.41,28.04-13.41-26.83-12.83Z"/>
          <g>
            <circle class="cls-4" cx="13.15" cy="65.88" r="2.43"/>
            <circle class="cls-4" cx="5.36" cy="70.28" r="2.31"/>
            <circle class="cls-4" cx="20.88" cy="61.41" r="2.43"/>
            <circle class="cls-4" cx="50.72" cy="70.28" r="2.31"/>
            <circle class="cls-4" cx="35.21" cy="61.41" r="2.43"/>
            <ellipse class="cls-4" cx="37" cy="48.92" rx="3" ry="1.5"/>
            <ellipse class="cls-4" cx="28" cy="43.92" rx="3" ry="1.5"/>
            <ellipse class="cls-4" cx="19" cy="38.92" rx="3" ry="1.5"/>
            <ellipse class="cls-4" cx="71" cy="7.92" rx="3" ry="1.5"/>
            <ellipse class="cls-4" cx="61" cy="11.92" rx="3" ry="1.5"/>
            <ellipse class="cls-4" cx="51" cy="17.92" rx="3" ry="1.5"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="mobile" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139 139" v-if="device == 'MOBILE'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-4" d="M0,15V124c0,8.25,6.75,15,15,15H124c8.25,0,15-6.75,15-15V15c0-4.07-1.65-7.79-4.31-10.49-.23-.24-.47-.47-.72-.68C131.32,1.45,127.81,0,124,0H15C6.75,0,0,6.75,0,15Z"/>
          <g class="cls-5">
            <polygon class="cls-2" points="4.19 133.91 16 122.09 16 61 15 61 15 121.68 3.48 133.2 4.19 133.91"/>
            <polygon class="cls-2" points="16 86 22 86 22 76 21 76 21 85 16 85 16 86"/>
            <polygon class="cls-2" points="15.47 76.63 16.11 75.86 10.23 70.97 9.27 67.13 8.3 67.37 9.34 71.53 15.47 76.63"/>
            <path class="cls-2" d="M5.79,95.75c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-2" d="M19.79,94.75c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <path class="cls-2" d="M18.79,73.75c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <path class="cls-2" d="M6.29,65.25c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-2" d="M13.47,59.25c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <polygon class="cls-2" points="16.6 113.1 23 106.71 23 97 22 97 22 106.29 15.9 112.4 16.6 113.1"/>
            <rect class="cls-2" x="12.15" y="96.08" width="1" height="9.37" transform="translate(-67.54 38.46) rotate(-45)"/>
          </g>
          <g class="cls-3">
            <path class="cls-2" d="M122,15.9v61.1s1,0,1,0V16.32s11.86-11.87,11.86-11.87c-.23-.24-.47-.47-.72-.68l-12.14,12.13Z"/>
            <polygon class="cls-2" points="117 62 118 62 118 53 123 53 123 52 117 52 117 62"/>
            <polygon class="cls-2" points="129.31 71.04 130.28 70.79 129.24 66.63 123.12 61.53 122.48 62.3 128.35 67.19 129.31 71.04"/>
            <path class="cls-2" d="M127.8,42.41c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <path class="cls-2" d="M113.8,43.41c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-2" d="M114.8,64.41c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <path class="cls-2" d="M127.3,72.91c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <path class="cls-2" d="M120.12,78.91c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <polygon class="cls-2" points="116 41 117 41 117 31.71 123.1 25.6 122.4 24.9 116 31.29 116 41"/>
            <rect class="cls-2" x="125.44" y="32.71" width="1" height="9.37" transform="translate(10.44 100.01) rotate(-45)"/>
          </g>
        </g>
        <g>
          <path class="cls-7" d="M78.5,45.25l3.8-5.99,13.39-2.23v16.79c0,.67-.38,1.27-.98,1.56l-16.21,7.75-3.87-11.21,3.87-6.67Z"/>
          <path class="cls-1" d="M58.94,64.07l-3.8-5.99-13.39-2.23v16.79c0,.67,.38,1.27,.98,1.56l16.21,7.75,3.87-11.21-3.87-6.67Z"/>
          <path class="cls-1" d="M78.53,45.26v17.85s-16.18-7.74-16.18-7.74c-.6-.29-.98-.89-.98-1.56v-16.76s17.17,8.21,17.17,8.21Z"/>
          <path class="cls-2" d="M77.94,29.17c.47-.23,1.02-.23,1.49,0l16.45,7.86-17.19,8.22-17.19-8.22,16.45-7.86Z"/>
          <g>
            <circle class="cls-6" cx="87.82" cy="50.57" r="1.49"/>
            <circle class="cls-6" cx="92.59" cy="44.1" r="1.42"/>
            <circle class="cls-6" cx="92.59" cy="53.26" r="1.42"/>
            <circle class="cls-6" cx="83.08" cy="47.83" r="1.49"/>
            <circle class="cls-6" cx="83.08" cy="57.03" r="1.49"/>
            <circle class="cls-6" cx="64.78" cy="44.1" r="1.42"/>
          </g>
          <circle cx="64.78" cy="53.26" r="1.42"/>
          <circle class="cls-6" cx="74.29" cy="47.83" r="1.49"/>
          <circle cx="74.29" cy="57.03" r="1.49"/>
          <path class="cls-7" d="M58.91,64.08v17.85s16.18-7.74,16.18-7.74c.6-.29,.98-.89,.98-1.56v-16.76s-17.17,8.21-17.17,8.21Z"/>
          <path class="cls-2" d="M59.68,47.99c-.47-.23-1.02-.23-1.49,0l-16.45,7.86,17.19,8.22,17.19-8.22-16.45-7.86Z"/>
          <g>
            <circle class="cls-6" cx="49.8" cy="69.39" r="1.49"/>
            <circle class="cls-6" cx="45.03" cy="72.08" r="1.42"/>
            <circle class="cls-6" cx="54.54" cy="66.65" r="1.49"/>
            <circle class="cls-6" cx="72.84" cy="72.08" r="1.42"/>
            <circle class="cls-6" cx="63.33" cy="66.65" r="1.49"/>
            <ellipse class="cls-6" cx="64.43" cy="58.99" rx="1.84" ry=".92"/>
            <ellipse class="cls-6" cx="58.91" cy="55.93" rx="1.84" ry=".92"/>
            <ellipse class="cls-6" cx="53.39" cy="52.86" rx="1.84" ry=".92"/>
            <ellipse class="cls-6" cx="85.27" cy="33.85" rx="1.84" ry=".92"/>
            <ellipse class="cls-6" cx="79.14" cy="36.31" rx="1.84" ry=".92"/>
            <ellipse class="cls-6" cx="73.01" cy="39.99" rx="1.84" ry=".92"/>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    device: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
#mobile {
  .cls-1 {
    fill: #ddd;
  }

  .cls-2 {
    fill: #fff;
  }

  .cls-3 {
    opacity: .51;
  }

  .cls-4 {
    fill: #f9bb5a;
  }

  .cls-5 {
    opacity: .21;
  }

  .cls-6 {
    fill: #2d2d2d;
  }

  .cls-7 {
    fill: #ededed;
  }
}
  #light {
    .cls-1 {
      fill: #ddd;
    }

    .cls-2 {
      fill: #828282;
    }

    .cls-3 {
      fill: #cecece;
    }

    .cls-4 {
      fill: #f2f2f2;
    }
  }

  #dark {
    .cls-1 {
      fill: #ffb129;
    }

    .cls-2 {
      opacity: .51;
    }

    .cls-3 {
      fill: #f9bb5a;
    }

    .cls-4 {
      fill: #f29a0c;
    }

    .cls-5 {
      fill: #2d2d2d;
    }
  }

  #hover {
    display: none;

    .cls-1 {
      fill: #ffb129;
    }

    .cls-2 {
      fill: #f9bb5a;
    }

    .cls-3 {
      fill: #f29a0c;
    }

    .cls-4 {
      fill: #2d2d2d;
    }
  }

  .g25-menus-card-item:hover {
    #hover {
      display: block;
    }
    #light, #dark {
      display: none;
    }
  }
</style>
