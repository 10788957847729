<template>
  <div class="g25-main-icon">
    <svg id="light" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 104.66"  v-if="variant == 'light' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <g>
            <path class="cls-4" d="M57.85,0H15.27C6.84,0,0,6.84,0,15.27v59.58c0,4.5,3.65,8.15,8.15,8.15H57.85c4.5,0,8.15-3.65,8.15-8.15V8.15c0-4.5-3.65-8.15-8.15-8.15Z"/>
            <path class="cls-1" d="M0,15.27v59.58c0,4.5,3.65,8.15,8.15,8.15H57.59c4.42,0,8.01-3.54,8.1-7.94h0c-30.3-.16-48.84-38.63-39.74-75.06H15.27C6.84,0,0,6.84,0,15.27Z"/>
            <g>
              <path class="cls-2" d="M47.4,40H18.36c-.77,0-1.45-.55-1.55-1.31-.11-.91,.6-1.69,1.49-1.69h29.03c.77,0,1.45,.55,1.55,1.31,.11,.91-.6,1.69-1.49,1.69Z"/>
              <path class="cls-2" d="M47.4,49H18.36c-.77,0-1.45-.55-1.55-1.31-.11-.91,.6-1.69,1.49-1.69h29.03c.77,0,1.45,.55,1.55,1.31,.11,.91-.6,1.69-1.49,1.69Z"/>
            </g>
            <path class="cls-3" d="M15.27,0C6.84,0,0,6.84,0,15.27v4.98c0-2.34,1.45-6.45,5.65-6.45,4.5,0,8.15-3.65,8.15-8.15C13.8,1.45,17.9,0,20.25,0h-4.98Z"/>
            <path class="cls-3" d="M23,64.46c0-2.47,2.06-4.46,4.59-4.46h9.92c2.48,0,4.48,2.01,4.48,4.48v23.52h4.14c1.95,0,2.9,2.39,1.48,3.73l-12.69,11.97c-1.36,1.29-3.5,1.29-4.86,0l-12.7-11.97c-1.42-1.34-.47-3.72,1.48-3.72h4.15v-23.54h0Z"/>
            <path class="cls-3" d="M34.71,103.6l7.73-7.49c-14.39,3.3-13.83-14.95-13.77-36.11h-1.19c-2.48,0-4.48,2.01-4.48,4.48v23.52h-3.99c-1.94,0-2.9,2.36-1.5,3.71l12.26,11.89c1.38,1.33,3.56,1.33,4.94,0Z"/>
          </g>
          <g>
            <path class="cls-2" d="M22.93,27.23c-.99,0-1.89-.16-2.72-.48s-1.54-.77-2.16-1.35c-.61-.58-1.09-1.27-1.43-2.07s-.51-1.66-.51-2.6,.17-1.81,.51-2.6,.81-1.48,1.43-2.07c.62-.58,1.34-1.03,2.17-1.35s1.74-.48,2.72-.48c1.07,0,2.04,.17,2.9,.52,.86,.35,1.59,.86,2.17,1.53l-1.48,1.44c-.49-.49-1.03-.86-1.6-1.09-.57-.23-1.2-.35-1.89-.35s-1.27,.11-1.84,.32-1.05,.52-1.46,.92c-.41,.4-.72,.87-.95,1.41-.22,.54-.33,1.14-.33,1.79s.11,1.23,.33,1.77c.22,.54,.54,1.01,.95,1.42,.41,.4,.89,.71,1.44,.93,.55,.22,1.17,.32,1.84,.32,.62,0,1.23-.1,1.81-.3,.58-.2,1.14-.53,1.67-1l1.33,1.75c-.66,.53-1.43,.93-2.3,1.2-.87,.27-1.75,.41-2.64,.41Zm3.07-1.5v-4.73h2v5.03l-2-.31Z"/>
            <path class="cls-2" d="M30,27v-1.58l4.85-4.8c.41-.4,.71-.75,.91-1.05s.33-.59,.39-.85,.1-.51,.1-.74c0-.58-.19-1.03-.57-1.36-.39-.32-.95-.48-1.69-.48-.59,0-1.13,.11-1.61,.33s-.9,.55-1.25,1l-1.59-1.27c.48-.67,1.12-1.18,1.93-1.55s1.71-.56,2.71-.56c.88,0,1.65,.15,2.31,.45s1.16,.72,1.52,1.26,.53,1.19,.53,1.95c0,.41-.05,.82-.16,1.23s-.3,.83-.59,1.28-.71,.95-1.27,1.51l-4.17,4.13-.47-.89h7.13v2h-9Z"/>
            <path class="cls-2" d="M44.74,26.98c-.9,0-1.77-.13-2.63-.39-.85-.26-1.57-.62-2.15-1.08l1-1.84c.46,.38,1.02,.68,1.68,.91s1.35,.34,2.06,.34c.84,0,1.49-.17,1.97-.53s.72-.82,.72-1.42c0-.4-.1-.75-.3-1.05-.2-.3-.55-.53-1.05-.69-.5-.16-1.19-.24-2.07-.24h-3.19l.66-7h7.55v2h-6.72l1.26-1.15-.5,5.28-1.26-1.13h2.73c1.26,0,2.28,.17,3.04,.5,.77,.33,1.33,.79,1.69,1.38,.36,.59,.54,1.26,.54,2.01s-.18,1.42-.55,2.04-.92,1.12-1.66,1.49c-.75,.38-1.69,.56-2.83,.56Z"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="dark" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 105.65" v-if="variant == 'dark' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="normal" data-name="Layer 1">
        <g class="cls-1">
          <path class="cls-2" d="M.5,15.77v59.58c0,4.5,3.65,8.15,8.15,8.15H58.09c4.42,0,8.01-3.54,8.1-7.94h0c-30.3-.16-48.84-38.63-39.74-75.06H15.77C7.34,.5,.5,7.34,.5,15.77Z"/>
          <path class="cls-3" d="M58.35,.5H15.77C7.34,.5,.5,7.34,.5,15.77v59.58c0,4.5,3.65,8.15,8.15,8.15H58.35c4.5,0,8.15-3.65,8.15-8.15V8.65c0-4.5-3.65-8.15-8.15-8.15Z"/>
          <g>
            <path class="cls-4" d="M47.9,40.5H18.86c-.77,0-1.45-.55-1.55-1.31-.11-.91,.6-1.69,1.49-1.69h29.03c.77,0,1.45,.55,1.55,1.31,.11,.91-.6,1.69-1.49,1.69Z"/>
            <path class="cls-4" d="M47.9,49.5H18.86c-.77,0-1.45-.55-1.55-1.31-.11-.91,.6-1.69,1.49-1.69h29.03c.77,0,1.45,.55,1.55,1.31,.11,.91-.6,1.69-1.49,1.69Z"/>
          </g>
          <path class="cls-3" d="M46.64,88.5h-4.14v-23.52c0-2.48-2.01-4.48-4.48-4.48h-10.04c-2.06,0-3.77,1.39-4.3,3.28-.03,.1-.07,.19-.09,.29-.01,.06,0,.12-.02,.17-.04,.23-.07,.47-.07,.71h0v23.54h-4.15c-1.95,0-2.9,2.39-1.48,3.72l5.63,5.31,6.77,6.56c.53,.51,1.17,.82,1.84,.94,1.14,.29,2.4,.02,3.31-.84l12.69-11.97c1.42-1.34,.47-3.73-1.48-3.73Z"/>
          <g>
            <path class="cls-4" d="M23.43,27.73c-.99,0-1.89-.16-2.72-.48-.82-.32-1.54-.77-2.16-1.35-.61-.58-1.09-1.27-1.43-2.07s-.51-1.66-.51-2.6,.17-1.81,.51-2.6c.34-.79,.81-1.48,1.43-2.07,.62-.58,1.34-1.03,2.17-1.35s1.74-.48,2.72-.48c1.07,0,2.04,.17,2.9,.52,.86,.35,1.59,.86,2.17,1.53l-1.48,1.44c-.49-.49-1.03-.86-1.6-1.09-.57-.23-1.2-.35-1.89-.35s-1.27,.11-1.84,.32-1.05,.52-1.46,.92c-.41,.4-.72,.87-.95,1.41-.22,.54-.33,1.14-.33,1.79s.11,1.23,.33,1.77c.22,.54,.54,1.01,.95,1.42,.41,.4,.89,.71,1.44,.93,.55,.22,1.17,.32,1.84,.32,.62,0,1.23-.1,1.81-.3,.58-.2,1.14-.53,1.67-1l1.33,1.75c-.66,.53-1.43,.93-2.3,1.2-.87,.27-1.75,.41-2.64,.41Zm3.07-1.5v-4.73h2v5.03l-2-.31Z"/>
            <path class="cls-4" d="M30.5,27.5v-1.58l4.85-4.8c.41-.4,.71-.75,.91-1.05s.33-.59,.39-.85,.1-.51,.1-.74c0-.58-.19-1.03-.57-1.36-.39-.32-.95-.48-1.69-.48-.59,0-1.13,.11-1.61,.33s-.9,.55-1.25,1l-1.59-1.27c.48-.67,1.12-1.18,1.93-1.55s1.71-.56,2.71-.56c.88,0,1.65,.15,2.31,.45s1.16,.72,1.52,1.26,.53,1.19,.53,1.95c0,.41-.05,.82-.16,1.23s-.3,.83-.59,1.28-.71,.95-1.27,1.51l-4.17,4.13-.47-.89h7.13v2h-9Z"/>
            <path class="cls-4" d="M45.24,27.48c-.9,0-1.77-.13-2.63-.39-.85-.26-1.57-.62-2.15-1.08l1-1.84c.46,.38,1.02,.68,1.68,.91s1.35,.34,2.06,.34c.84,0,1.49-.17,1.97-.53s.72-.82,.72-1.42c0-.4-.1-.75-.3-1.05-.2-.3-.55-.53-1.05-.69-.5-.16-1.19-.24-2.07-.24h-3.19l.66-7h7.55v2h-6.72l1.26-1.15-.5,5.28-1.26-1.13h2.73c1.26,0,2.28,.17,3.04,.5,.77,.33,1.33,.79,1.69,1.38,.36,.59,.54,1.26,.54,2.01s-.18,1.42-.55,2.04-.92,1.12-1.66,1.49c-.75,.38-1.69,.56-2.83,.56Z"/>
          </g>
        </g>
      </g>
      <g id="hover" data-name="Layer 1">
        <g>
          <path class="cls-1" d="M.5,15.77v59.58c0,4.5,3.65,8.15,8.15,8.15H58.09c4.42,0,8.01-3.54,8.1-7.94h0c-30.3-.16-48.84-38.63-39.74-75.06H15.77C7.34,.5,.5,7.34,.5,15.77Z"/>
          <path class="cls-2" d="M58.35,.5H15.77C7.34,.5,.5,7.34,.5,15.77v59.58c0,4.5,3.65,8.15,8.15,8.15H58.35c4.5,0,8.15-3.65,8.15-8.15V8.65c0-4.5-3.65-8.15-8.15-8.15Z"/>
          <g>
            <path class="cls-3" d="M47.9,40.5H18.86c-.77,0-1.45-.55-1.55-1.31-.11-.91,.6-1.69,1.49-1.69h29.03c.77,0,1.45,.55,1.55,1.31,.11,.91-.6,1.69-1.49,1.69Z"/>
            <path class="cls-3" d="M47.9,49.5H18.86c-.77,0-1.45-.55-1.55-1.31-.11-.91,.6-1.69,1.49-1.69h29.03c.77,0,1.45,.55,1.55,1.31,.11,.91-.6,1.69-1.49,1.69Z"/>
          </g>
          <path class="cls-2" d="M46.64,88.5h-4.14v-23.52c0-2.48-2.01-4.48-4.48-4.48h-10.04c-2.06,0-3.77,1.39-4.3,3.28-.03,.1-.07,.19-.09,.29-.01,.06,0,.12-.02,.17-.04,.23-.07,.47-.07,.71h0v23.54h-4.15c-1.95,0-2.9,2.39-1.48,3.72l5.63,5.31,6.77,6.56c.53,.51,1.17,.82,1.84,.94,1.14,.29,2.4,.02,3.31-.84l12.69-11.97c1.42-1.34,.47-3.73-1.48-3.73Z"/>
          <g>
            <path class="cls-3" d="M23.43,27.73c-.99,0-1.89-.16-2.72-.48-.82-.32-1.54-.77-2.16-1.35-.61-.58-1.09-1.27-1.43-2.07s-.51-1.66-.51-2.6,.17-1.81,.51-2.6c.34-.79,.81-1.48,1.43-2.07,.62-.58,1.34-1.03,2.17-1.35s1.74-.48,2.72-.48c1.07,0,2.04,.17,2.9,.52,.86,.35,1.59,.86,2.17,1.53l-1.48,1.44c-.49-.49-1.03-.86-1.6-1.09-.57-.23-1.2-.35-1.89-.35s-1.27,.11-1.84,.32-1.05,.52-1.46,.92c-.41,.4-.72,.87-.95,1.41-.22,.54-.33,1.14-.33,1.79s.11,1.23,.33,1.77c.22,.54,.54,1.01,.95,1.42,.41,.4,.89,.71,1.44,.93,.55,.22,1.17,.32,1.84,.32,.62,0,1.23-.1,1.81-.3,.58-.2,1.14-.53,1.67-1l1.33,1.75c-.66,.53-1.43,.93-2.3,1.2-.87,.27-1.75,.41-2.64,.41Zm3.07-1.5v-4.73h2v5.03l-2-.31Z"/>
            <path class="cls-3" d="M30.5,27.5v-1.58l4.85-4.8c.41-.4,.71-.75,.91-1.05s.33-.59,.39-.85,.1-.51,.1-.74c0-.58-.19-1.03-.57-1.36-.39-.32-.95-.48-1.69-.48-.59,0-1.13,.11-1.61,.33s-.9,.55-1.25,1l-1.59-1.27c.48-.67,1.12-1.18,1.93-1.55s1.71-.56,2.71-.56c.88,0,1.65,.15,2.31,.45s1.16,.72,1.52,1.26,.53,1.19,.53,1.95c0,.41-.05,.82-.16,1.23s-.3,.83-.59,1.28-.71,.95-1.27,1.51l-4.17,4.13-.47-.89h7.13v2h-9Z"/>
            <path class="cls-3" d="M45.24,27.48c-.9,0-1.77-.13-2.63-.39-.85-.26-1.57-.62-2.15-1.08l1-1.84c.46,.38,1.02,.68,1.68,.91s1.35,.34,2.06,.34c.84,0,1.49-.17,1.97-.53s.72-.82,.72-1.42c0-.4-.1-.75-.3-1.05-.2-.3-.55-.53-1.05-.69-.5-.16-1.19-.24-2.07-.24h-3.19l.66-7h7.55v2h-6.72l1.26-1.15-.5,5.28-1.26-1.13h2.73c1.26,0,2.28,.17,3.04,.5,.77,.33,1.33,.79,1.69,1.38,.36,.59,.54,1.26,.54,2.01s-.18,1.42-.55,2.04-.92,1.12-1.66,1.49c-.75,.38-1.69,.56-2.83,.56Z"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="mobile" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139 139" v-if="device == 'MOBILE'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-6" d="M139,15V124c0,8.25-6.75,15-15,15H15c-8.25,0-15-6.75-15-15V15C0,10.93,1.65,7.21,4.31,4.51c.23-.24,.47-.47,.72-.68C7.68,1.45,11.19,0,15,0H124c8.25,0,15,6.75,15,15Z"/>
          <g class="cls-4">
            <polygon class="cls-6" points="134.81 133.91 123 122.09 123 61 124 61 124 121.68 135.52 133.2 134.81 133.91"/>
            <polygon class="cls-6" points="124 86 118 86 118 75 119 75 119 85 124 85 124 86"/>
            <polygon class="cls-6" points="124.11 76.63 123.47 75.86 129.34 70.97 130.3 67.13 131.27 67.37 130.23 71.53 124.11 76.63"/>
            <path class="cls-6" d="M131.29,98.25c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-6" d="M117.29,97.25c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-6" d="M118.29,76.25c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-6" d="M130.79,67.75c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-6" d="M123.61,61.75c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <polygon class="cls-6" points="123.4 113.1 117 106.71 117 97 118 97 118 106.29 124.1 112.4 123.4 113.1"/>
            <rect class="cls-6" x="122.25" y="100.26" width="9.37" height="1" transform="translate(-34.07 119.27) rotate(-45)"/>
          </g>
          <g class="cls-3">
            <path class="cls-6" d="M17,15.9v61.1h-1V16.32L4.14,4.45c.23-.24,.47-.47,.72-.68l12.14,12.13Z"/>
            <polygon class="cls-6" points="23 63 22 63 22 53 17 53 17 52 23 52 23 63"/>
            <polygon class="cls-6" points="10.27 71.04 9.3 70.79 10.34 66.63 16.46 61.53 17.1 62.3 11.23 67.19 10.27 71.04"/>
            <path class="cls-6" d="M9.28,44.91c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-6" d="M23.28,45.91c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-6" d="M22.28,66.91c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-6" d="M9.78,75.41c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-6" d="M16.96,81.41c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <polygon class="cls-6" points="24 41 23 41 23 31.71 16.9 25.6 17.6 24.9 24 31.29 24 41"/>
            <rect class="cls-6" x="8.95" y="36.9" width="9.37" height="1" transform="translate(-22.45 20.6) rotate(-45)"/>
          </g>
        </g>
        <g>
          <g>
            <path class="cls-2" d="M86.69,16.47h-27.74c-5.49,0-9.95,4.45-9.95,9.95v38.82c0,2.93,2.38,5.31,5.31,5.31h32.38c2.93,0,5.31-2.38,5.31-5.31V21.78c0-2.93-2.38-5.31-5.31-5.31Z"/>
            <path class="cls-1" d="M49,26.42v38.82c0,2.93,2.38,5.31,5.31,5.31h32.21c2.88,0,5.22-2.3,5.28-5.17h0c-19.74-.1-31.82-25.17-25.89-48.91h-6.96c-5.49,0-9.95,4.45-9.95,9.95Z"/>
            <g>
              <path class="cls-5" d="M79.88,42.53h-18.91c-.5,0-.95-.36-1.01-.86-.07-.59,.39-1.1,.97-1.1h18.91c.5,0,.95,.36,1.01,.86,.07,.59-.39,1.1-.97,1.1Z"/>
              <path class="cls-5" d="M79.88,48.4h-18.91c-.5,0-.95-.36-1.01-.86-.07-.59,.39-1.1,.97-1.1h18.91c.5,0,.95,.36,1.01,.86,.07,.59-.39,1.1-.97,1.1Z"/>
            </g>
            <path class="cls-5" d="M58.95,16.47c-5.49,0-9.95,4.45-9.95,9.95v3.24c0-1.53,.95-4.2,3.68-4.2,2.93,0,5.31-2.38,5.31-5.31,0-2.74,2.67-3.68,4.2-3.68h-3.24Z"/>
            <path class="cls-5" d="M63.98,58.47c0-1.61,1.34-2.91,2.99-2.91h6.47c1.61,0,2.92,1.31,2.92,2.92v15.32h2.7c1.27,0,1.89,1.56,.96,2.43l-8.27,7.8c-.89,.84-2.28,.84-3.17,0l-8.27-7.8c-.92-.87-.31-2.43,.96-2.43h2.7v-15.33h0Z"/>
            <path class="cls-5" d="M71.62,83.97l5.03-4.88c-9.38,2.15-9.01-9.74-8.97-23.52h-.77c-1.61,0-2.92,1.31-2.92,2.92v15.32h-2.6c-1.26,0-1.89,1.54-.98,2.42l7.99,7.74c.9,.87,2.32,.87,3.22,0Z"/>
          </g>
          <g>
            <path class="cls-5" d="M63.94,34.21c-.64,0-1.23-.1-1.77-.31s-1-.5-1.4-.88c-.4-.38-.71-.83-.93-1.35s-.33-1.08-.33-1.69,.11-1.18,.33-1.69,.53-.96,.93-1.35c.4-.38,.88-.67,1.42-.88s1.13-.31,1.78-.31c.7,0,1.33,.11,1.89,.34,.56,.23,1.03,.56,1.42,1l-.96,.94c-.32-.32-.67-.56-1.04-.71-.37-.15-.78-.23-1.23-.23s-.83,.07-1.2,.21-.69,.34-.95,.6c-.27,.26-.47,.56-.62,.92-.15,.35-.22,.74-.22,1.16s.07,.8,.22,1.15c.14,.35,.35,.66,.62,.92,.27,.26,.58,.46,.94,.6,.36,.14,.76,.21,1.2,.21,.41,0,.8-.06,1.18-.19,.38-.13,.74-.35,1.09-.65l.87,1.14c-.43,.35-.93,.61-1.5,.78-.57,.18-1.14,.26-1.72,.26Zm2-.98v-3.08h1.3v3.28l-1.3-.2Z"/>
            <path class="cls-5" d="M68.55,34.06v-1.03l3.16-3.13c.27-.26,.46-.49,.59-.69s.21-.38,.26-.55,.06-.33,.06-.48c0-.38-.12-.67-.37-.88-.25-.21-.62-.31-1.1-.31-.39,0-.74,.07-1.05,.21s-.58,.36-.81,.65l-1.03-.83c.31-.43,.73-.77,1.26-1.01s1.12-.36,1.77-.36c.58,0,1.08,.1,1.51,.29s.76,.47,.99,.82,.35,.78,.35,1.27c0,.27-.03,.53-.1,.8s-.2,.54-.39,.84-.47,.62-.83,.98l-2.72,2.69-.31-.58h4.65v1.3h-5.86Z"/>
            <path class="cls-5" d="M78.15,34.05c-.58,0-1.15-.08-1.71-.25-.56-.17-1.02-.41-1.4-.71l.65-1.2c.3,.24,.67,.44,1.1,.59s.88,.22,1.34,.22c.54,0,.97-.11,1.29-.34s.47-.54,.47-.92c0-.26-.07-.49-.2-.69-.13-.2-.36-.35-.69-.45-.33-.1-.78-.15-1.35-.15h-2.08l.43-4.56h4.92v1.3h-4.38l.82-.75-.33,3.44-.82-.74h1.78c.82,0,1.48,.11,1.98,.33,.5,.22,.87,.52,1.1,.9,.23,.38,.35,.82,.35,1.31s-.12,.92-.36,1.33-.6,.73-1.08,.97c-.49,.25-1.1,.37-1.84,.37Z"/>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    device: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.g25-main-icon #light, .g25-main-icon #dark  {
  height: 80px;
}

#mobile {
  .cls-1 {
    fill: #f4f4f4;
  }

  .cls-2 {
    fill: #fff;
  }

  .cls-3 {
    opacity: .51;
  }

  .cls-4 {
    opacity: .21;
  }

  .cls-5 {
    fill: #2d2d2d;
  }

  .cls-6 {
    fill: #6fceaa;
  }
}

#light {
  .cls-1 {
    fill: #ddd;
  }

  .cls-2 {
    fill: #495059;
  }

  .cls-3 {
    fill: #828282;
  }

  .cls-4 {
    fill: #f2f2f2;
  }
}

#dark {
  .cls-1 {
    opacity: .51;
  }

  .cls-2, .cls-3 {
    fill: #1d2328;
  }

  .cls-3 {
    stroke: #6fceaa;
    stroke-miterlimit: 10;
  }

  .cls-4 {
    fill: #6fceaa;
  }
}

#normal {
  display: block;
}
#hover {
  display: none;
}

.g25-menus-card-item:hover {
  #light {
    .cls-1 {
      fill: #f2f2f2;
    }

    .cls-2 {
      fill: #495059;
    }

    .cls-3 {
      fill: #6fceaa;
    }

    .cls-4 {
      fill: #fff;
    }
  }
  #dark {
    .cls-1, .cls-2 {
      fill: #1d2328;
    }

    .cls-2 {
      stroke: #6fceaa;
      stroke-miterlimit: 10;
    }

    .cls-3 {
      fill: #6fceaa;
    }
  }

  #normal {
    display: none;
  }
  #hover {
    display: block;
  }
}
</style>
