<template>
  <div class="g25-main-icon">
    <svg id="light" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.89 105.37"  v-if="variant == 'light' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-3" d="M56.6,22.37c-1.32,0-1.32-2,0-2h13.63c1.32,0,1.32,2,0,2h-13.63Zm.04-10h13.53c1.33,0,1.33,2,0,2h-13.53c-1.31,0-1.31-2,0-2Z"/>
          <path class="cls-3" d="M58.32,37.37h11.78c1.33,0,1.33,2,0,2h-11.78c-1.33,0-1.33-2,0-2Zm-.7-8h12.42c1.31,0,1.31,2,0,2h-12.42c-1.31,0-1.31-2,0-2Z"/>
          <path class="cls-3" d="M56.64,4.37h13.53c1.33,0,1.33,2,0,2h-13.53c-1.31,0-1.31-2,0-2Z"/>
          <path class="cls-3" d="M56.53,45.37h13.78c1,0,1,2,0,2h-13.78c-1,0-1-2,0-2Z"/>
          <path class="cls-6" d="M63.42,10.41c-3.96,0-7.29,3.18-7.29,7.17,0,3.99,3.33,7.14,7.29,7.14,5.17,0,9.47,4.14,9.47,9.35,0,5.17-4.3,9.32-9.47,9.32-3.96,0-7.29,3.18-7.29,7.17,0,1.42-2.18,1.42-2.18,0,0-5.2,4.3-9.35,9.47-9.35,3.96,0,7.29-3.15,7.29-7.14s-3.33-7.17-7.29-7.17c-5.17,0-9.47-4.14-9.47-9.32,0-5.2,4.3-9.35,9.47-9.35,3.96,0,7.29-3.15,7.29-7.14,0-1.45,2.18-1.45,2.18,0,0,5.17-4.3,9.32-9.47,9.32Z"/>
          <path class="cls-1" d="M53.95,1.09c0-1.45,2.18-1.45,2.18,0,0,3.99,3.33,7.14,7.29,7.14,5.17,0,9.47,4.14,9.47,9.35s-4.3,9.32-9.47,9.32c-3.96,0-7.29,3.18-7.29,7.17s3.33,7.14,7.29,7.14c5.17,0,9.47,4.14,9.47,9.35,0,1.42-2.18,1.42-2.18,0,0-3.99-3.33-7.17-7.29-7.17-5.17,0-9.47-4.14-9.47-9.32s4.3-9.35,9.47-9.35c3.96,0,7.29-3.15,7.29-7.14s-3.33-7.17-7.29-7.17c-5.17,0-9.47-4.14-9.47-9.32Z"/>
        </g>
        <g id="Layer_1-3" data-name="Layer_1">
          <g>
            <path class="cls-7" d="M10.73,103.97l2.58-9.7c.39-1.4,2.58-.84,2.19,.62l-2.63,9.7c-.11,.5-.56,.78-1.07,.78-.73,0-1.29-.67-1.07-1.4Z"/>
            <path class="cls-8" d="M1.2,79.37h6.84c1.46-.51,1.46,1.73,0,2H1.2c-1.46-.27-1.46-2.51,0-2Z"/>
            <path class="cls-7" d="M44.92,104.59l-2.58-9.7c-.39-1.46,1.74-2.02,2.13-.62l2.63,9.7c.17,.73-.39,1.4-1.12,1.4-.5,0-.95-.28-1.07-.78Z"/>
            <path class="cls-7" d="M2.71,71.37h7.06c1.46-.42,1.46,1.83,0,2H2.71c-1.46-.17-1.46-2.42,0-2Zm17.71,0h4.93c1.46-.42,1.46,1.83,0,2h-4.93c-1.46-.17-1.46-2.42,0-2Zm12.05,0h4.88c1.51-.42,1.51,1.83,0,2h-4.88c-1.51-.17-1.51-2.42,0-2Zm15.58,0h7.01c1.46-.42,1.46,1.83,0,2h-7.01c-1.51-.17-1.51-2.42,0-2Z"/>
            <path class="cls-7" d="M.62,82.84v-5.66c-.54-1.46,1.71-1.46,2,0v5.66c-.29,.62-.74,1.12-1.41,1.12-.62,0-1.12-.5-.59-1.12Z"/>
            <path class="cls-7" d="M22.62,24.34v-8.26c0-3.07,2.2-5.83,5.25-6.19,3.64-.43,6.75,2.43,6.75,5.98v8.46c0,1.49-10.29,1.11-10.89,1.11s-1.11-.5-1.11-1.11Z"/>
          </g>
          <path class="cls-2" d="M42.34,94.89l-11.15-41.48c-.34-1.46,1.79-2.02,2.19-.62,2.8-1.35,5.21-5.33,6.11-6.95l-.17-.56c-.39-1.46,1.79-2.02,2.19-.56l10.15,37.78c1.57,5.89-2.07,11.88-8.24,13.23-.5,0-.95-.39-1.07-.84Z"/>
          <path class="cls-2" d="M14.09,95.68c-6.11-1.68-9.47-7.4-7.9-13.17l10.15-37.78c.34-1.46,2.52-.9,2.13,.56l-.28,1.07c1.23,1.57,3.7,4.37,6.45,6.05,.67-.84,2.3-.28,1.96,1.01l-11.1,41.48c-.17,.45-.62,.84-1.12,.84-.06,0-.17-.06-.28-.06Z"/>
          <path class="cls-4" d="M15.62,41.98v-15.61c0-1.61,1.34-3,3.03-3h19.95c1.68,0,3.03,1.39,3.03,3v15.5c0,6.9-5.6,12.5-12.5,12.5h-.95c-6.95,0-12.55-5.56-12.55-12.39Z"/>
          <path class="cls-7" d="M22.5,38.95c0-3.53,2.86-6.39,6.39-6.39s6.45,2.86,6.45,6.39-2.91,6.45-6.45,6.45-6.39-2.86-6.39-6.45Z"/>
          <path class="cls-5" d="M28.89,67.37c6.17,0,6.17,9.36,0,9.36s-6.11-9.36,0-9.36Z"/>
        </g>
      </g>
    </svg>

    <svg id="dark" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.89 105.37" v-if="variant == 'dark' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g class="cls-1">
          <g>
            <path class="cls-6" d="M56.49,22.37c-1.32,0-1.32-2,0-2h13.63c1.32,0,1.32,2,0,2h-13.63Zm.04-10h13.53c1.33,0,1.33,2,0,2h-13.53c-1.31,0-1.31-2,0-2Z"/>
            <path class="cls-6" d="M58.21,37.37h11.78c1.33,0,1.33,2,0,2h-11.78c-1.33,0-1.33-2,0-2Zm-.7-8h12.42c1.31,0,1.31,2,0,2h-12.42c-1.31,0-1.31-2,0-2Z"/>
            <path class="cls-6" d="M56.53,4.37h13.53c1.33,0,1.33,2,0,2h-13.53c-1.31,0-1.31-2,0-2Z"/>
            <path class="cls-6" d="M56.42,45.37h13.78c1,0,1,2,0,2h-13.78c-1,0-1-2,0-2Z"/>
            <path class="cls-3" d="M63.31,10.41c-3.96,0-7.29,3.18-7.29,7.17,0,3.99,3.33,7.14,7.29,7.14,5.17,0,9.47,4.14,9.47,9.35,0,5.17-4.3,9.32-9.47,9.32-3.96,0-7.29,3.18-7.29,7.17,0,1.42-2.18,1.42-2.18,0,0-5.2,4.3-9.35,9.47-9.35,3.96,0,7.29-3.15,7.29-7.14s-3.33-7.17-7.29-7.17c-5.17,0-9.47-4.14-9.47-9.32,0-5.2,4.3-9.35,9.47-9.35,3.96,0,7.29-3.15,7.29-7.14,0-1.45,2.18-1.45,2.18,0,0,5.17-4.3,9.32-9.47,9.32Z"/>
            <path class="cls-5" d="M53.84,1.09c0-1.45,2.18-1.45,2.18,0,0,3.99,3.33,7.14,7.29,7.14,5.17,0,9.47,4.14,9.47,9.35s-4.3,9.32-9.47,9.32c-3.96,0-7.29,3.18-7.29,7.17s3.33,7.14,7.29,7.14c5.17,0,9.47,4.14,9.47,9.35,0,1.42-2.18,1.42-2.18,0,0-3.99-3.33-7.17-7.29-7.17-5.17,0-9.47-4.14-9.47-9.32s4.3-9.35,9.47-9.35c3.96,0,7.29-3.15,7.29-7.14s-3.33-7.17-7.29-7.17c-5.17,0-9.47-4.14-9.47-9.32Z"/>
          </g>
          <g id="Layer_1-3" data-name="Layer_1">
            <g>
              <path class="cls-3" d="M10.62,103.97l2.58-9.7c.39-1.4,2.58-.84,2.19,.62l-2.63,9.7c-.11,.5-.56,.78-1.07,.78-.73,0-1.29-.67-1.07-1.4Z"/>
              <path class="cls-3" d="M1.09,79.37H7.93c1.46-.51,1.46,1.73,0,2H1.09c-1.46-.27-1.46-2.51,0-2Z"/>
              <path class="cls-3" d="M44.81,104.59l-2.58-9.7c-.39-1.46,1.74-2.02,2.13-.62l2.63,9.7c.17,.73-.39,1.4-1.12,1.4-.5,0-.95-.28-1.07-.78Z"/>
              <path class="cls-3" d="M2.61,71.37h7.06c1.46-.42,1.46,1.83,0,2H2.61c-1.46-.17-1.46-2.42,0-2Zm17.71,0h4.93c1.46-.42,1.46,1.83,0,2h-4.93c-1.46-.17-1.46-2.42,0-2Zm12.05,0h4.88c1.51-.42,1.51,1.83,0,2h-4.88c-1.51-.17-1.51-2.42,0-2Zm15.58,0h7.01c1.46-.42,1.46,1.83,0,2h-7.01c-1.51-.17-1.51-2.42,0-2Z"/>
              <path class="cls-3" d="M.51,82.84v-5.66c-.54-1.46,1.71-1.46,2,0v5.66c-.29,.62-.74,1.12-1.41,1.12-.62,0-1.12-.5-.59-1.12Z"/>
              <path class="cls-4" d="M22.51,24.34v-8.26c0-3.07,2.2-5.83,5.25-6.19,3.64-.43,6.75,2.43,6.75,5.98v8.46c0,1.49-10.29,1.11-10.89,1.11s-1.11-.5-1.11-1.11Z"/>
            </g>
            <path class="cls-2" d="M42.24,94.89l-11.15-41.48c-.34-1.46,1.79-2.02,2.19-.62,2.8-1.35,5.21-5.33,6.11-6.95l-.17-.56c-.39-1.46,1.79-2.02,2.19-.56l10.15,37.78c1.57,5.89-2.07,11.88-8.24,13.23-.5,0-.95-.39-1.07-.84Z"/>
            <path class="cls-2" d="M13.99,95.68c-6.11-1.68-9.47-7.4-7.9-13.17l10.15-37.78c.34-1.46,2.52-.9,2.13,.56l-.28,1.07c1.23,1.57,3.7,4.37,6.45,6.05,.67-.84,2.3-.28,1.96,1.01l-11.1,41.48c-.17,.45-.62,.84-1.12,.84-.06,0-.17-.06-.28-.06Z"/>
            <path class="cls-6" d="M15.51,41.98v-15.61c0-1.61,1.34-3,3.03-3h19.95c1.68,0,3.03,1.39,3.03,3v15.5c0,6.9-5.6,12.5-12.5,12.5h-.95c-6.95,0-12.55-5.56-12.55-12.39Z"/>
            <path class="cls-4" d="M22.39,38.95c0-3.53,2.86-6.39,6.39-6.39,3.53,0,6.45,2.86,6.45,6.39s-2.91,6.45-6.45,6.45-6.39-2.86-6.39-6.45Z"/>
            <path class="cls-4" d="M28.78,67.37c6.17,0,6.17,9.36,0,9.36-6.11,0-6.11-9.36,0-9.36Z"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="mobile" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139 139" v-if="device == 'MOBILE'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-8" d="M139,15V124c0,8.25-6.75,15-15,15H15c-8.25,0-15-6.75-15-15V15C0,10.93,1.65,7.21,4.31,4.51c.23-.24,.47-.47,.72-.68C7.68,1.45,11.19,0,15,0H124c8.25,0,15,6.75,15,15Z"/>
          <g class="cls-7">
            <polygon class="cls-1" points="134.81 133.91 123 122.09 123 61 124 61 124 121.68 135.52 133.2 134.81 133.91"/>
            <polygon class="cls-1" points="124 86 118 86 118 75 119 75 119 85 124 85 124 86"/>
            <polygon class="cls-1" points="124.11 76.63 123.47 75.86 129.34 70.97 130.3 67.13 131.27 67.37 130.23 71.53 124.11 76.63"/>
            <path class="cls-1" d="M131.29,98.25c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-1" d="M117.29,97.25c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-1" d="M118.29,76.25c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-1" d="M130.79,67.75c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-1" d="M123.61,61.75c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <polygon class="cls-1" points="123.4 113.1 117 106.71 117 97 118 97 118 106.29 124.1 112.4 123.4 113.1"/>
            <rect class="cls-1" x="122.25" y="100.26" width="9.37" height="1" transform="translate(-34.07 119.27) rotate(-45)"/>
          </g>
          <g class="cls-3">
            <path class="cls-1" d="M17,15.9v61.1h-1V16.32L4.14,4.45c.23-.24,.47-.47,.72-.68l12.14,12.13Z"/>
            <polygon class="cls-1" points="23 63 22 63 22 53 17 53 17 52 23 52 23 63"/>
            <polygon class="cls-1" points="10.27 71.04 9.3 70.79 10.34 66.63 16.46 61.53 17.1 62.3 11.23 67.19 10.27 71.04"/>
            <path class="cls-1" d="M9.28,44.91c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-1" d="M23.28,45.91c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-1" d="M22.28,66.91c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-1" d="M9.78,75.41c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-1" d="M16.96,81.41c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <polygon class="cls-1" points="24 41 23 41 23 31.71 16.9 25.6 17.6 24.9 24 31.29 24 41"/>
            <rect class="cls-1" x="8.95" y="36.9" width="9.37" height="1" transform="translate(-22.45 20.6) rotate(-45)"/>
          </g>
        </g>
        <g>
          <g>
            <path class="cls-2" d="M84.94,34.97c-.88,0-.88-1.33,0-1.33h9.04c.88,0,.88,1.33,0,1.33h-9.04Zm.03-6.63h8.97c.88,0,.88,1.33,0,1.33h-8.97c-.87,0-.87-1.33,0-1.33Z"/>
            <path class="cls-2" d="M86.08,44.92h7.81c.88,0,.88,1.33,0,1.33h-7.81c-.88,0-.88-1.33,0-1.33Zm-.46-5.3h8.23c.87,0,.87,1.33,0,1.33h-8.23c-.87,0-.87-1.33,0-1.33Z"/>
            <path class="cls-2" d="M84.97,23.04h8.97c.88,0,.88,1.33,0,1.33h-8.97c-.87,0-.87-1.33,0-1.33Z"/>
            <path class="cls-2" d="M84.9,50.22h9.13c.66,0,.66,1.33,0,1.33h-9.13c-.66,0-.66-1.33,0-1.33Z"/>
            <path class="cls-5" d="M89.46,27.04c-2.63,0-4.83,2.11-4.83,4.75,0,2.65,2.21,4.73,4.83,4.73,3.43,0,6.28,2.75,6.28,6.2s-2.85,6.18-6.28,6.18c-2.63,0-4.83,2.11-4.83,4.75,0,.94-1.44,.94-1.44,0,0-3.45,2.85-6.2,6.28-6.2,2.63,0,4.83-2.09,4.83-4.73s-2.21-4.75-4.83-4.75c-3.43,0-6.28-2.75-6.28-6.18,0-3.45,2.85-6.2,6.28-6.2,2.63,0,4.83-2.09,4.83-4.73,0-.96,1.44-.96,1.44,0,0,3.43-2.85,6.18-6.28,6.18Z"/>
            <path class="cls-4" d="M83.19,20.86c0-.96,1.44-.96,1.44,0,0,2.65,2.21,4.73,4.83,4.73,3.43,0,6.28,2.75,6.28,6.2s-2.85,6.18-6.28,6.18c-2.63,0-4.83,2.11-4.83,4.75s2.21,4.73,4.83,4.73c3.43,0,6.28,2.75,6.28,6.2,0,.94-1.44,.94-1.44,0,0-2.65-2.21-4.75-4.83-4.75-3.43,0-6.28-2.75-6.28-6.18s2.85-6.2,6.28-6.2c2.63,0,4.83-2.09,4.83-4.73s-2.21-4.75-4.83-4.75c-3.43,0-6.28-2.75-6.28-6.18Z"/>
          </g>
          <g id="Layer_1-3" data-name="Layer_1">
            <g>
              <path class="cls-6" d="M54.53,89.07l1.71-6.43c.26-.93,1.71-.56,1.45,.41l-1.75,6.43c-.07,.33-.37,.52-.71,.52-.48,0-.85-.45-.71-.93Z"/>
              <path class="cls-6" d="M48.22,72.76h4.53c.97-.34,.97,1.15,0,1.33h-4.53c-.97-.18-.97-1.67,0-1.33Z"/>
              <path class="cls-6" d="M77.2,89.48l-1.71-6.43c-.26-.97,1.15-1.34,1.41-.41l1.75,6.43c.11,.48-.26,.93-.74,.93-.33,0-.63-.19-.71-.52Z"/>
              <path class="cls-6" d="M49.22,67.46h4.68c.97-.28,.97,1.21,0,1.33h-4.68c-.97-.12-.97-1.6,0-1.33Zm11.74,0h3.27c.97-.28,.97,1.21,0,1.33h-3.27c-.97-.12-.97-1.6,0-1.33Zm7.99,0h3.23c1-.28,1,1.21,0,1.33h-3.23c-1-.12-1-1.6,0-1.33Zm10.33,0h4.65c.97-.28,.97,1.21,0,1.33h-4.65c-1-.12-1-1.6,0-1.33Z"/>
              <path class="cls-6" d="M47.83,75.06v-3.75c-.35-.97,1.13-.97,1.33,0v3.75c-.19,.41-.49,.74-.94,.74-.41,0-.74-.33-.39-.74Z"/>
              <path class="cls-6" d="M62.41,36.28v-5.48c0-2.03,1.46-3.86,3.48-4.1,2.41-.28,4.48,1.61,4.48,3.97v5.61c0,.99-6.82,.73-7.22,.73s-.73-.33-.73-.73Z"/>
            </g>
            <path class="cls-4" d="M75.49,83.05l-7.4-27.5c-.22-.97,1.19-1.34,1.45-.41,1.86-.89,3.46-3.53,4.05-4.61l-.11-.37c-.26-.97,1.19-1.34,1.45-.37l6.73,25.05c1.04,3.9-1.37,7.88-5.46,8.77-.33,0-.63-.26-.71-.56Z"/>
            <path class="cls-4" d="M56.76,83.57c-4.05-1.11-6.28-4.91-5.24-8.73l6.73-25.05c.22-.97,1.67-.59,1.41,.37l-.19,.71c.82,1.04,2.45,2.9,4.27,4.01,.45-.56,1.52-.19,1.3,.67l-7.36,27.5c-.11,.3-.41,.56-.74,.56-.04,0-.11-.04-.19-.04Z"/>
            <path class="cls-2" d="M57.77,47.98v-10.35c0-1.07,.89-1.99,2.01-1.99h13.22c1.11,0,2.01,.92,2.01,1.99v10.28c0,4.58-3.71,8.29-8.29,8.29h-.63c-4.61,0-8.32-3.68-8.32-8.21Z"/>
            <path class="cls-5" d="M62.34,45.96c0-2.34,1.9-4.24,4.24-4.24s4.27,1.9,4.27,4.24c0,2.38-1.93,4.27-4.27,4.27s-4.24-1.9-4.24-4.27Z"/>
            <path class="cls-5" d="M66.57,64.8c4.09,0,4.09,6.21,0,6.21-4.05,0-4.05-6.21,0-6.21Z"/>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    device: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.g25-main-icon #light, .g25-main-icon #dark  {
  height: 80px;
}

#mobile {
  .cls-1, .cls-2 {
    fill: #fff;
  }

  .cls-3 {
    opacity: .51;
  }

  .cls-2, .cls-4, .cls-5, .cls-6 {
    fill-rule: evenodd;
  }

  .cls-4 {
    fill: #2d2d2d;
  }

  .cls-5 {
    fill: #ddd;
  }

  .cls-7 {
    opacity: .21;
  }

  .cls-8 {
    fill: #fc8db0;
  }

  .cls-6 {
    fill: #ededed;
  }
}

#light {
  .cls-1 {
    fill: #828282;
  }

  .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8 {
    fill-rule: evenodd;
  }

  .cls-2 {
    fill: #8b8b8b;
  }

  .cls-3 {
    fill: #ddd;
  }

  .cls-4 {
    fill: #e7e7e8;
  }

  .cls-5 {
    fill: #d6d6d6;
  }

  .cls-6 {
    fill: #cecece;
  }

  .cls-7, .cls-8 {
    fill: #c7c7c7;
  }

  .cls-8 {
    stroke: #c7c7c7;
    stroke-miterlimit: 10;
    stroke-width: .22px;
  }
}

#dark {
  .cls-1 {
    opacity: .51;
  }

  .cls-2 {
    fill: #1d2328;
    stroke: #fc8db0;
    stroke-miterlimit: 10;
  }

  .cls-2, .cls-3, .cls-4, .cls-5, .cls-6 {
    fill-rule: evenodd;
  }

  .cls-3 {
    fill: #f779ac;
  }

  .cls-4 {
    fill: #f94b8a;
  }

  .cls-5 {
    fill: #dd5794;
  }

  .cls-6 {
    fill: #fc8db0;
  }
}

.g25-menus-card-item:hover {
  #light {
    .cls-1 {
     fill: #f779ac;
    }

    .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8 {
      fill-rule: evenodd;
    }

    .cls-2 {
      fill: #2D2D2D;
    }

    .cls-3 {
      fill: #f779ac;
    }

    .cls-4 {
      fill: #f779ac;
    }

    .cls-5 {
      fill: #f94b8a;
    }

    .cls-6 {
      fill: #2D2D2D;
    }

    .cls-7, .cls-8 {
      fill: #f94b8a;
    }

    .cls-8 {
      stroke: #c7c7c7;
      stroke-miterlimit: 10;
      stroke-width: .22px;
    }
  }
  #dark {
    .cls-1 {
      fill: #e27d95;
      opacity: 1;
    }

    .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8 {
      fill-rule: evenodd;
    }

    .cls-2 {
      fill: #2D2D2D;
    }

    .cls-3 {
      fill: #f779ac;
    }

    .cls-4 {
      fill: #f94b8a;
    }

    .cls-5 {
      fill: #f94b8a;
    }

    .cls-6 {
      fill: #fc8db0;
    }

    .cls-7, .cls-8 {
      fill: #f94b8a;
    }

    .cls-8 {
      stroke: #c7c7c7;
      stroke-miterlimit: 10;
      stroke-width: .22px;
    }
  }
}
</style>
