<template>
  <div>
    <div class="g25-menus-card hidden-g25-dashboard-mobile">
      <div class="g25-menus-card-header">
        <h4>Genetic Distances</h4>
      </div>
      <div class="g25-menus-card-body">
        <div>
          <div class="g25-menus-card-item mr-1" @click="$router.push({ name: 'order-result-g25', params: { type: G25_RESULT_TABS.MODERN_GENETIC_DISTANCES.code }})">
            <genetic-dist-modern-icon :variant="$store.state.appConfig.layout.skin" device="DESKTOP"/>
            <div class="text">Modern</div>
          </div>
        </div>
        <div>
          <div class="g25-menus-card-item mr-1" @click="$router.push({ name: 'order-result-g25', params: { type: G25_RESULT_TABS.ANCESTRY_GENETIC_DISTANCES.code }})">
            <genetic-dist-ancient-icon :variant="$store.state.appConfig.layout.skin" device="DESKTOP"/>
            <div class="text">Ancient</div>
          </div>
        </div>
        <div>
          <div class="g25-menus-card-item mr-1" @click="$router.push({ name: 'order-result-g25', params: { type: G25_RESULT_TABS.PLOT_PCA.code }})">
            <genetic-dist-pca-icon :variant="$store.state.appConfig.layout.skin" device="DESKTOP"/>
            <div class="text">PCA Plots</div>
          </div>
        </div>
      </div>
    </div>

    <b-row  class="g25-menus-card hidden-g25-dashboard-desktop">
      <b-col cols="12">
        <div class="g25-menus-card-header mb-1">
          <h4>Genetic Distances</h4>
        </div>
      </b-col>
      <b-col cols="6" >
        <div class="g25-menus-card-mobile-item" @click="$router.push({ name: 'order-result-g25', params: { type: G25_RESULT_TABS.MODERN_GENETIC_DISTANCES.code }})">
          <genetic-dist-modern-icon :variant="$store.state.appConfig.layout.skin" device="MOBILE"/>
          <div class="text mobile-text">Modern</div>
        </div>
      </b-col>
      <b-col cols="6" >
        <div class="g25-menus-card-mobile-item" @click="$router.push({ name: 'order-result-g25', params: { type: G25_RESULT_TABS.ANCESTRY_GENETIC_DISTANCES.code }})">
          <genetic-dist-ancient-icon :variant="$store.state.appConfig.layout.skin" device="MOBILE"/>
          <div class="text mobile-text">Ancient</div>
        </div>
      </b-col>
      <b-col cols="12" class="mt-1">
        <div class="g25-menus-card-mobile-item" @click="$router.push({ name: 'order-result-g25', params: { type: G25_RESULT_TABS.PLOT_PCA.code }})">
          <genetic-dist-pca-icon :variant="$store.state.appConfig.layout.skin" device="MOBILE"/>
          <div class="text pca">PCA Plots</div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { G25_RESULT_TABS } from '@/common/config'
import GeneticDistModernIcon from '@/layouts/icons/g25/GeneticDistModernIcon.vue'
import GeneticDistAncientIcon from '@/layouts/icons/g25/GeneticDistAncientIcon.vue'
import GeneticDistPcaIcon from '@/layouts/icons/g25/GeneticDistPcaIcon.vue'

export default {
  components: {
    GeneticDistModernIcon,
    GeneticDistAncientIcon,
    GeneticDistPcaIcon,
  },
  data() {
    return {
      G25_RESULT_TABS,
    }
  },
  computed: {

  },
  created() {

  },
}
</script>

<style scoped lang="scss">
.dark-layout .g25-menus-card-header h4{
  color:  var(--lab-g25-genetic-dist-color) !important;
}
.g25-menus-card-header::before {
  background-color: var(--lab-g25-genetic-dist-color) !important;
}

.dark-layout .g25-menus-card-item .text {
  color:  var(--lab-g25-genetic-dist-color) !important;
  opacity: 0.5;
}
.dark-layout .g25-menus-card-item {
  border-color:  #e27d957d !important;
}

.g25-menus-card-item:hover {
  transform: scale(1.1);
  border-color:  var(--lab-g25-genetic-dist-color) !important;
  .text {
    color:  var(--lab-g25-genetic-dist-color) !important;
    opacity: 1;
  }
}
</style>
