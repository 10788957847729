<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-download-coordiantes"
      title="Download Coordinates"  
      cancel-variant="outline-secondary"
      @hidden="hiddenModal"
      size="lg"
      content-class="download-cords-dist-modern"
    >
      <b-row class="p-2">
        <b-col sm="6" md="6">
          <label class="lab-checkbox-container">
            <span class="label"> {{ 'Select All' }}</span>
            <input type="checkbox" v-model="isActiveAll" @change="onToggleActive()">
            <span class="checkmark"></span>
          </label>
        </b-col>
        <b-col sm="6" md="6">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              size="sm"
              placeholder="Search..."
            />
        </b-col>
      </b-row>
      
      <div class="select-data-card cs-scroll-style">
        <div class="pop-item" :class="{ 'selected': item.isActive }"
          v-for="item in orderResults" :key="item.id">

        <label class="lab-checkbox-container">
           <span class="label">{{item.resultTitle}}</span>
          <input type="checkbox" v-model="item.isActive" @change="controlCheck">
          <span class="checkmark"></span>
        </label>
        </div>
      </div>

      <template #modal-footer>
        <!-- <b-button variant="secondary" class="float-right" @click="hiddenModal">
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          {{ $t("Close") }}
        </b-button> -->
        <button class="lab-btn float-right" @click="downloadCoordinates">
          {{ $t("Download") }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'

export default {
  components: {
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      orderResults: [],
      searchQuery: '',
      isActiveAll: false,
    }
  },
  methods: {
    controlCheck() {
      this.orderResults = this.orderResults.filter(item => item.resultTitle.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
    onToggleActive() {
      this.orderResults.forEach(item => {
        item.isActive = this.isActiveAll;
      });
    },
    initialModal() {
      this.orderResults = this.$store.getters['orderResult/getOrderResults'].map(item => item);
      this.orderResults.forEach(item => {
        item.isActive = this.isActiveAll;
      });
      this.$refs['modal-download-coordiantes'].show();
    },
    hiddenModal() {
      this.$refs['modal-download-coordiantes'].hide()
      this.modalObject.showModal = false;
    },

    downloadCoordinates() {
      /* eslint-disable */
      const selectedResults = this.orderResults.filter(x => x.isActive);
      if (selectedResults.length > 0) {
        let text = '';
        
        selectedResults.forEach(item => {
          if (item.targetCoordinate !== null && item.targetCoordinate !== '') {
            text += item.targetCoordinate;
          }
          if (item.targetUnScaledCoordinate !== null && item.targetUnScaledCoordinate !== '') {
            text += '\n' + item.targetUnScaledCoordinate;
          }
          text += '\n'; 
        });
        
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', 'coordinates');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } else {
        AlertService.warning(this, 'There is no selected kit')
      }
    },
  },
  computed: {
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss" scoped>
.lab-btn {
  background-color: var(--lab-g25-supplementary-color) !important;
}
.select-data-card-header {
  background-clip: border-box;
  border-radius: 0.428rem !important;
  margin-bottom: 0.7rem;
  padding: 0.5rem 1.2rem;
}

.select-data-search-input {
  float: right !important;

}

.select-data-card {
  height: 400px !important;
  overflow-y: scroll !important;
}

.pop-item {
  padding: 1rem;
  margin-bottom: 5px;
  border-radius: 5px;
}
.pop-item:hover {
  background: var(--bg-card-gray);
}
.pop-item.selected {
  background: var(--bg-card-gray);
}

.lab-checkbox-container input:checked ~ .checkmark {
  background-color: var(--lab-g25-supplementary-color) !important;
}

.dark-layout .lab-checkbox-container .label {
  color: var(--lab-g25-supplementary-color);
}
.dark-layout .download-cords-dist-modern .lab-checkbox-container input:checked ~ .checkmark {
  background-color: var(--lab-g25-supplementary-color) !important;
}
.dark-layout .lab-checkbox-container:hover input ~ .checkmark {
  background-color: var(--lab-g25-supplementary-color);
}
</style>

<style lang="scss">
.download-cords-dist-modern {
  .modal-header::before {
    background-color: var(--lab-g25-supplementary-color) !important;
  }
  .modal-header .close {
    background-color: #6fceaa4d !important;
    color: var(--lab-g25-supplementary-color) !important;
  }
  .title-header::before {
    background-color: var(--lab-g25-supplementary-color) !important;
  }
}

.download-cords-dist-modern {
  .modal-header h5 {
    color: var(--lab-g25-supplementary-color) !important;
  }
  .title-header h4 {
    color: var(--lab-g25-supplementary-color) !important;
  }
}

</style>
