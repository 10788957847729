<template>
  <div class="g25-main-icon">
    <svg id="light" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.98 99"  v-if="variant == 'light' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <g id="Layer_1-3" data-name="Layer_1">
            <path class="cls-2" d="M57.58,66.47c-.4,0-.79-.15-1.08-.45l-8.65-8.63c-.67-.67-.57-1.79,.2-2.33,2.7-1.91,5.07-4.27,6.97-6.97,.55-.78,1.66-.87,2.33-.2l8.63,8.63c.6,.6,.6,1.57,0,2.17l-7.34,7.34c-.3,.3-.7,.45-1.08,.45Z"/>
            <path class="cls-1" d="M84.13,97c-1.46,0-2.85-.57-3.87-1.61l-23.78-23.76c-2.13-2.14-2.13-5.62,0-7.76l7.35-7.35c2.14-2.14,5.63-2.14,7.78,0l23.76,23.76c2.14,2.14,2.14,5.63,0,7.78l-7.35,7.34c-1.04,1.04-2.41,1.61-3.89,1.61Z"/>
            <path class="cls-3" d="M76.56,91.71l-17.11-17.11,15.11-15.11,2.17,2.16,14.94,14.95-15.11,15.11Z"/>
            <path class="cls-1" d="M31.63,63.29c-8.46,0-16.41-3.29-22.38-9.26C-3.08,41.69-3.08,21.61,9.25,9.28c12.37-12.37,32.38-12.37,44.75,0,12.33,12.33,12.33,32.41,0,44.75-5.97,5.97-13.93,9.26-22.37,9.26Z"/>
            <path class="cls-3" d="M31.63,3.71c-7.47,0-14.49,2.91-19.76,8.18C.97,22.8,.97,40.52,11.87,51.41c10.91,10.91,28.6,10.91,39.52,0,10.9-10.9,10.9-28.62,0-39.52-5.28-5.28-12.29-8.18-19.75-8.18Z"/>
          </g>
          <g>
            <path class="cls-2" d="M25.3,29c-1.15,.3-1.15-1.44,0-2h11.85c1.15,.56,1.15,2.3,0,2h-11.85Zm11.9-6h-11.92c-1.18-.12-1.18-1.88,0-2h11.92c1.15,.12,1.15,1.88,0,2Z"/>
            <path class="cls-2" d="M35.78,43h-10.38c-1.18-.26-1.18-2.02,0-2h10.38c1.18-.02,1.18,1.74,0,2Zm.59-7h-10.95c-1.15,.3-1.15-1.46,0-2h10.95c1.15,.54,1.15,2.3,0,2Z"/>
            <path class="cls-2" d="M37.2,16h-11.92c-1.18-.12-1.18-1.88,0-2h11.92c1.15,.12,1.15,1.88,0,2Z"/>
            <path class="cls-2" d="M37.3,50h-12.15c-.88-.26-.88-2.02,0-2h12.15c.88-.02,.88,1.74,0,2Z"/>
            <path class="cls-4" d="M23.56,11.84c0-1.18,1.76-1.18,1.76,0,0,3.23,2.69,5.78,5.9,5.78,4.19,0,7.66,3.35,7.66,7.57,0,4.19-3.48,7.54-7.66,7.54-3.21,0-5.9,2.57-5.9,5.8s2.69,5.78,5.9,5.78c4.19,0,7.66,3.35,7.66,7.57,0,1.15-1.76,1.15-1.76,0,0-3.23-2.69-5.8-5.9-5.8-4.19,0-7.66-3.35-7.66-7.54s3.48-7.57,7.66-7.57c3.21,0,5.9-2.55,5.9-5.78s-2.69-5.8-5.9-5.8c-4.19,0-7.66-3.35-7.66-7.54Z"/>
            <path class="cls-1" d="M31.22,19.38c-3.21,0-5.9,2.57-5.9,5.8s2.69,5.78,5.9,5.78c4.19,0,7.66,3.35,7.66,7.57,0,4.19-3.48,7.54-7.66,7.54-3.21,0-5.9,2.57-5.9,5.8,0,1.15-1.76,1.15-1.76,0,0-4.21,3.48-7.57,7.66-7.57,3.21,0,5.9-2.55,5.9-5.78,0-3.23-2.69-5.8-5.9-5.8-4.19,0-7.66-3.35-7.66-7.54,0-4.21,3.48-7.57,7.66-7.57,3.21,0,5.9-2.55,5.9-5.78,0-1.18,1.76-1.18,1.76,0,0,4.19-3.48,7.54-7.66,7.54Z"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="dark" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.98 99" v-if="variant == 'dark' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <path class="cls-4" d="M59.08,67.97c-.4,0-.79-.15-1.08-.45l-8.65-8.63c-.67-.67-.57-1.79,.2-2.33,2.7-1.91,5.07-4.27,6.97-6.97,.55-.78,1.66-.87,2.33-.2l8.63,8.63c.6,.6,.6,1.57,0,2.17l-7.34,7.34c-.3,.3-.7,.45-1.08,.45Z"/>
        <path class="cls-6" d="M85.63,98.5c-1.46,0-2.85-.57-3.87-1.61l-23.78-23.76c-2.13-2.14-2.13-5.62,0-7.76l7.35-7.35c2.14-2.14,5.63-2.14,7.78,0l23.76,23.76c2.14,2.14,2.14,5.63,0,7.78l-7.35,7.34c-1.04,1.04-2.41,1.61-3.89,1.61Z"/>
        <path class="cls-4" d="M78.06,93.21l-17.11-17.11,15.11-15.11,2.17,2.16,14.94,14.95-15.11,15.11Z"/>
        <path class="cls-3" d="M33.13,64.79c-8.46,0-16.41-3.29-22.38-9.26C-1.58,43.19-1.58,23.11,10.75,10.78,23.12-1.59,43.13-1.59,55.5,10.78c12.33,12.33,12.33,32.41,0,44.75-5.97,5.97-13.93,9.26-22.37,9.26Z"/>
        <g class="cls-7">
          <path class="cls-5" d="M26.8,30.5c-1.15,.3-1.15-1.44,0-2h11.85c1.15,.56,1.15,2.3,0,2h-11.85Zm11.9-6h-11.92c-1.18-.12-1.18-1.88,0-2h11.92c1.15,.12,1.15,1.88,0,2Z"/>
          <path class="cls-5" d="M37.28,44.5h-10.38c-1.18-.26-1.18-2.02,0-2h10.38c1.18-.02,1.18,1.74,0,2Zm.59-7h-10.95c-1.15,.3-1.15-1.46,0-2h10.95c1.15,.54,1.15,2.3,0,2Z"/>
          <path class="cls-5" d="M38.7,17.5h-11.92c-1.18-.12-1.18-1.88,0-2h11.92c1.15,.12,1.15,1.88,0,2Z"/>
          <path class="cls-5" d="M38.8,51.5h-12.15c-.88-.26-.88-2.02,0-2h12.15c.88-.02,.88,1.74,0,2Z"/>
          <path class="cls-1" d="M25.06,13.34c0-1.18,1.76-1.18,1.76,0,0,3.23,2.69,5.78,5.9,5.78,4.19,0,7.66,3.35,7.66,7.57,0,4.19-3.48,7.54-7.66,7.54-3.21,0-5.9,2.57-5.9,5.8s2.69,5.78,5.9,5.78c4.19,0,7.66,3.35,7.66,7.57,0,1.15-1.76,1.15-1.76,0,0-3.23-2.69-5.8-5.9-5.8-4.19,0-7.66-3.35-7.66-7.54s3.48-7.57,7.66-7.57c3.21,0,5.9-2.55,5.9-5.78s-2.69-5.8-5.9-5.8c-4.19,0-7.66-3.35-7.66-7.54Z"/>
          <path class="cls-2" d="M32.72,20.88c-3.21,0-5.9,2.57-5.9,5.8s2.69,5.78,5.9,5.78c4.19,0,7.66,3.35,7.66,7.57,0,4.19-3.48,7.54-7.66,7.54-3.21,0-5.9,2.57-5.9,5.8,0,1.15-1.76,1.15-1.76,0,0-4.21,3.48-7.57,7.66-7.57,3.21,0,5.9-2.55,5.9-5.78,0-3.23-2.69-5.8-5.9-5.8-4.19,0-7.66-3.35-7.66-7.54,0-4.21,3.48-7.57,7.66-7.57,3.21,0,5.9-2.55,5.9-5.78,0-1.18,1.76-1.18,1.76,0,0,4.19-3.48,7.54-7.66,7.54Z"/>
        </g>
      </g>
    </svg>

    <svg id="mobile" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139 139" v-if="device == 'MOBILE'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-7" d="M0,15V124c0,8.25,6.75,15,15,15H124c8.25,0,15-6.75,15-15V15c0-4.07-1.65-7.79-4.31-10.49-.23-.24-.47-.47-.72-.68C131.32,1.45,127.81,0,124,0H15C6.75,0,0,6.75,0,15Z"/>
          <g class="cls-8">
            <polygon class="cls-5" points="4.19 133.91 16 122.09 16 61 15 61 15 121.68 3.48 133.2 4.19 133.91"/>
            <polygon class="cls-5" points="16 86 22 86 22 76 21 76 21 85 16 85 16 86"/>
            <polygon class="cls-5" points="15.47 76.63 16.11 75.86 10.23 70.97 9.27 67.13 8.3 67.37 9.34 71.53 15.47 76.63"/>
            <path class="cls-5" d="M5.79,95.75c0-1.38,1.12-2.5,2.5-2.5,1.38,0,2.5,1.12,2.5,2.5,0,1.38-1.12,2.5-2.5,2.5-1.38,0-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-5" d="M19.79,94.75c0-1.38,1.12-2.5,2.5-2.5,1.38,0,2.5,1.12,2.5,2.5,0,1.38-1.12,2.5-2.5,2.5-1.38,0-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <path class="cls-5" d="M18.79,73.75c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <path class="cls-5" d="M6.29,65.25c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-5" d="M13.47,59.25c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <polygon class="cls-5" points="16.6 113.1 23 106.71 23 97 22 97 22 106.29 15.9 112.4 16.6 113.1"/>
            <rect class="cls-5" x="12.15" y="96.08" width="1" height="9.37" transform="translate(-67.54 38.46) rotate(-45)"/>
          </g>
          <g class="cls-6">
            <path class="cls-5" d="M122,15.9v61.1s1,0,1,0V16.32s11.86-11.87,11.86-11.87c-.23-.24-.47-.47-.72-.68l-12.14,12.13Z"/>
            <polygon class="cls-5" points="117 62 118 62 118 53 123 53 123 52 117 52 117 62"/>
            <polygon class="cls-5" points="129.31 71.04 130.28 70.79 129.24 66.63 123.12 61.53 122.48 62.3 128.35 67.19 129.31 71.04"/>
            <path class="cls-5" d="M127.8,42.41c0-1.38,1.12-2.5,2.5-2.5,1.38,0,2.5,1.12,2.5,2.5,0,1.38-1.12,2.5-2.5,2.5-1.38,0-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-5" d="M113.8,43.41c0-1.38,1.12-2.5,2.5-2.5,1.38,0,2.5,1.12,2.5,2.5s-1.12,2.5-2.5,2.5c-1.38,0-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-5" d="M114.8,64.41c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <path class="cls-5" d="M127.3,72.91c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <path class="cls-5" d="M120.12,78.91c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <polygon class="cls-5" points="116 41 117 41 117 31.71 123.1 25.6 122.4 24.9 116 31.29 116 41"/>
            <rect class="cls-5" x="125.44" y="32.71" width="1" height="9.37" transform="translate(10.44 100.01) rotate(-45)"/>
          </g>
        </g>
        <g>
          <g id="Layer_1-3" data-name="Layer_1">
            <path class="cls-2" d="M73.7,59.63c-.26,0-.53-.1-.72-.3l-5.77-5.76c-.45-.45-.38-1.19,.14-1.56,1.8-1.27,3.39-2.85,4.65-4.65,.36-.52,1.11-.58,1.56-.14l5.76,5.76c.4,.4,.4,1.05,0,1.45l-4.9,4.9c-.2,.2-.46,.3-.72,.3Z"/>
            <path class="cls-3" d="M91.43,80c-.97,0-1.9-.38-2.59-1.07l-15.87-15.86c-1.42-1.43-1.42-3.75,0-5.18l4.91-4.91c1.43-1.43,3.76-1.43,5.19,0l15.86,15.86c1.43,1.43,1.43,3.76,0,5.19l-4.91,4.9c-.69,.69-1.61,1.07-2.59,1.07Z"/>
            <path class="cls-2" d="M86.37,76.47l-11.42-11.42,10.09-10.09,1.45,1.44,9.97,9.98-10.09,10.09Z"/>
            <path class="cls-3" d="M56.38,57.51c-5.64,0-10.95-2.19-14.94-6.18-8.23-8.24-8.23-21.64,0-29.87,8.26-8.26,21.61-8.26,29.87,0s8.23,21.63,0,29.87c-3.99,3.99-9.3,6.18-14.93,6.18Z"/>
            <path class="cls-2" d="M56.38,17.74c-4.99,0-9.67,1.94-13.19,5.46-7.27,7.27-7.27,19.1,0,26.37,7.28,7.28,19.09,7.28,26.37,0s7.27-19.1,0-26.37c-3.52-3.52-8.2-5.46-13.18-5.46Z"/>
          </g>
          <g>
            <path class="cls-4" d="M52.16,34.62c-.77,.2-.77-.96,0-1.33h7.91c.77,.37,.77,1.53,0,1.33h-7.91Zm7.94-4h-7.96c-.78-.08-.78-1.26,0-1.33h7.96c.77,.08,.77,1.26,0,1.33Z"/>
            <path class="cls-4" d="M59.15,43.96h-6.93c-.78-.17-.78-1.35,0-1.33h6.93c.78-.02,.78,1.16,0,1.33Zm.39-4.67h-7.31c-.77,.2-.77-.98,0-1.33h7.31c.77,.36,.77,1.54,0,1.33Z"/>
            <path class="cls-4" d="M60.1,25.94h-7.96c-.78-.08-.78-1.26,0-1.33h7.96c.77,.08,.77,1.25,0,1.33Z"/>
            <path class="cls-4" d="M60.17,48.63h-8.11c-.59-.17-.59-1.35,0-1.33h8.11c.59-.01,.59,1.16,0,1.33Z"/>
            <path class="cls-1" d="M51,23.16c0-.78,1.18-.78,1.18,0,0,2.16,1.8,3.86,3.94,3.86,2.79,0,5.12,2.24,5.12,5.05s-2.32,5.03-5.12,5.03c-2.14,0-3.94,1.72-3.94,3.87s1.8,3.86,3.94,3.86c2.79,0,5.12,2.24,5.12,5.05,0,.77-1.18,.77-1.18,0,0-2.16-1.8-3.87-3.94-3.87-2.79,0-5.12-2.24-5.12-5.03s2.32-5.05,5.12-5.05c2.14,0,3.94-1.7,3.94-3.86s-1.8-3.87-3.94-3.87c-2.79,0-5.12-2.24-5.12-5.03Z"/>
            <path class="cls-3" d="M56.11,28.2c-2.14,0-3.94,1.72-3.94,3.87,0,2.16,1.8,3.86,3.94,3.86,2.79,0,5.12,2.24,5.12,5.05,0,2.79-2.32,5.03-5.12,5.03-2.14,0-3.94,1.72-3.94,3.87,0,.77-1.18,.77-1.18,0,0-2.81,2.32-5.05,5.12-5.05,2.14,0,3.94-1.7,3.94-3.86,0-2.16-1.8-3.87-3.94-3.87-2.79,0-5.12-2.24-5.12-5.03,0-2.81,2.32-5.05,5.12-5.05,2.14,0,3.94-1.7,3.94-3.86,0-.78,1.18-.78,1.18,0,0,2.79-2.32,5.03-5.12,5.03Z"/>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    device: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.g25-main-icon #light, .g25-main-icon #dark  {
  height: 80px;
}

#mobile {
  .cls-1 {
    fill: #4fb28a;
  }

  .cls-1, .cls-2, .cls-3, .cls-4 {
    fill-rule: evenodd;
  }

  .cls-5, .cls-2 {
    fill: #fff;
  }

  .cls-6 {
    opacity: .51;
  }

  .cls-3 {
    fill: #2d2d2d;
  }

  .cls-4, .cls-7 {
    fill: #6fceaa;
  }

  .cls-8 {
    opacity: .21;
  }
}

#light {
  .cls-1 {
    fill: #828282;
  }

  .cls-1, .cls-2, .cls-3, .cls-4 {
    fill-rule: evenodd;
  }

  .cls-2 {
    fill: #ddd;
  }

  .cls-3 {
    fill: #f2f2f2;
  }

  .cls-4 {
    fill: #cecece;
  }
}

#dark {
  .cls-1 {
    fill: #4fb28a;
  }

  .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6 {
    fill-rule: evenodd;
  }

  .cls-7, .cls-3, .cls-4, .cls-6 {
    opacity: .51;
  }

  .cls-2 {
    fill: #36966d;
  }

  .cls-3 {
    stroke-width: 3px;
  }

  .cls-3, .cls-6 {
    fill: #1d2328;
    stroke: #6fceaa;
    stroke-miterlimit: 10;
  }

  .cls-4, .cls-5 {
    fill: #6fceaa;
  }
}

.g25-menus-card-item:hover {
  #light {
    .cls-1 {
      fill: #4fb28a;
    }

    .cls-1, .cls-2, .cls-3, .cls-4 {
      fill-rule: evenodd;
    }

    .cls-2 {
      fill: #6fceaa;
    }

    .cls-3 {
      fill: #2d2d2d;
    }

    .cls-4 {
      fill: #6fceaa;
    }
  }
  #dark {
    .cls-7, .cls-3, .cls-4, .cls-6 {
      opacity: 1;
    }
  }
}
</style>
