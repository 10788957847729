<template>
  <div class="g25-main-icon">
    <svg id="light" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91 91"  v-if="variant == 'light' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-1" d="M71.62,61H26.23l5.88-40.36,39.51,40.36Zm-40.77-4h31.26l-27.21-27.79-4.05,27.79Z"/>
          <path class="cls-2" d="M89.56,82H1.5c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5H23v4.5c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5v-4.5h13v4.33c0,.8,.67,1.44,1.5,1.44s1.5-.65,1.5-1.44v-4.33h13v4.33c0,.8,.67,1.44,1.5,1.44s1.5-.65,1.5-1.44v-4.33h14v4.33c0,.8,.67,1.44,1.5,1.44s1.5-.65,1.5-1.44v-4.33h13v4.5c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5v-6.06c0-.8-.65-1.44-1.44-1.44Z"/>
          <path class="cls-1" d="M7.72,0H1.94C1.15,0,.5,.65,.5,1.44s.65,1.44,1.44,1.56H6v13H1.5C.67,16,0,16.67,0,17.5s.67,1.5,1.5,1.5H6v14H1.5C.67,33,0,33.67,0,34.5s.67,1.5,1.5,1.5H6v13H1.5C.67,49,0,49.67,0,50.5s.67,1.5,1.5,1.5H6v13H1.5C.67,65,0,65.67,0,66.5s.67,1.5,1.5,1.5H6v21.06s.92,1.44,1.72,1.44,1.44-.65,1.28-1.44V1.44c.16-.8-.48-1.44-1.28-1.44Z"/>
          <circle class="cls-2" cx="34" cy="25" r="5"/>
          <circle class="cls-2" cx="29" cy="59" r="5"/>
          <circle class="cls-2" cx="67" cy="59" r="5"/>
        </g>
      </g>
    </svg>

    <svg id="dark" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 91.5" v-if="variant == 'dark' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g class="cls-1">
          <path class="cls-3" d="M72.12,61H26.73l5.88-40.36,39.51,40.36Zm-40.77-4h31.26l-27.21-27.79-4.05,27.79Z"/>
          <path class="cls-2" d="M90.06,82H2c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5H23.5v4.5c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5v-4.5h13v4.33c0,.8,.67,1.44,1.5,1.44s1.5-.65,1.5-1.44v-4.33h13v4.33c0,.8,.67,1.44,1.5,1.44s1.5-.65,1.5-1.44v-4.33h14v4.33c0,.8,.67,1.44,1.5,1.44s1.5-.65,1.5-1.44v-4.33h13v4.5c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5v-6.06c0-.8-.65-1.44-1.44-1.44Z"/>
          <path class="cls-4" d="M8.22,0H2.44c-.8,0-1.44,.65-1.44,1.44s.65,1.44,1.44,1.56H6.5v13H2c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5H6.5v14H2c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5H6.5v13H2c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5H6.5v13H2c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5H6.5v21.06s.92,1.44,1.72,1.44,1.44-.65,1.28-1.44V1.44c.16-.8-.48-1.44-1.28-1.44Z"/>
          <circle class="cls-2" cx="34.5" cy="25" r="5"/>
          <circle class="cls-2" cx="29.5" cy="59" r="5"/>
          <circle class="cls-2" cx="67.5" cy="59" r="5"/>
        </g>
      </g>
    </svg>

    <svg id="mobile" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 302 102" v-if="device == 'MOBILE'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <rect class="cls-5" width="302" height="102" rx="15" ry="15"/>
          <g class="cls-2">
            <path class="cls-1" d="M284.1,16h-61.1v-1h60.68l11.87-11.86c.24,.23,.47,.47,.68,.72l-12.13,12.14Z"/>
            <polygon class="cls-1" points="237 21 237 20 247 20 247 15 248 15 248 21 237 21"/>
            <polygon class="cls-1" points="229 8.72 229.24 7.75 233.4 8.79 238.51 14.92 237.74 15.56 232.84 9.68 229 8.72"/>
            <path class="cls-1" d="M255.12,7.74c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm4,0c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Z"/>
            <path class="cls-1" d="M254.12,21.74c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm4,0c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Z"/>
            <path class="cls-1" d="M233.12,20.74c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm4,0c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Z"/>
            <path class="cls-1" d="M224.62,8.24c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm4,0c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Z"/>
            <path class="cls-1" d="M218.62,15.42c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm4,0c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Z"/>
            <polygon class="cls-1" points="259 22 259 21 268.29 21 274.4 14.9 275.1 15.6 268.71 22 259 22"/>
            <rect class="cls-1" x="262.14" y="7.41" width="1" height="9.37" transform="translate(68.37 189.26) rotate(-45)"/>
          </g>
          <g class="cls-3">
            <polygon class="cls-1" points="3.09 95.81 14.91 84 76 84 76 85 15.32 85 3.8 96.52 3.09 95.81"/>
            <polygon class="cls-1" points="51 84 51 78 62 78 62 79 52 79 52 84 51 84"/>
            <polygon class="cls-1" points="60.41 84.57 61.17 83.93 66.07 89.8 69.91 90.76 69.67 91.73 65.51 90.69 60.41 84.57"/>
            <path class="cls-1" d="M38.79,91.75c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm4,0c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Z"/>
            <path class="cls-1" d="M39.79,77.75c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm4,0c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Z"/>
            <path class="cls-1" d="M60.79,78.75c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm4,0c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Z"/>
            <path class="cls-1" d="M69.29,91.25c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm4,0c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Z"/>
            <path class="cls-1" d="M75.29,84.07c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm4,0c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Z"/>
            <polygon class="cls-1" points="23.9 83.4 30.29 77 40 77 40 78 30.71 78 24.6 84.1 23.9 83.4"/>
            <rect class="cls-1" x="35.77" y="82.7" width="1" height="9.37" transform="translate(-51.17 51.24) rotate(-45)"/>
          </g>
        </g>
        <g>
          <path class="cls-1" d="M83.8,54.56h-30.93l4.01-27.49,26.92,27.49Zm-27.78-2.73h21.3l-18.54-18.93-2.76,18.93Z"/>
          <path class="cls-6" d="M96.02,68.87H36.02c-.56,0-1.02,.46-1.02,1.02s.46,1.02,1.02,1.02h14.65v3.07c0,.56,.46,1.02,1.02,1.02s1.02-.46,1.02-1.02v-3.07h8.86v2.95c0,.54,.46,.98,1.02,.98s1.02-.44,1.02-.98v-2.95h8.86v2.95c0,.54,.46,.98,1.02,.98s1.02-.44,1.02-.98v-2.95h9.54v2.95c0,.54,.46,.98,1.02,.98s1.02-.44,1.02-.98v-2.95h8.86v3.07c0,.56,.46,1.02,1.02,1.02s1.02-.46,1.02-1.02v-4.13c0-.54-.44-.98-.98-.98Z"/>
          <path class="cls-1" d="M40.26,13h-3.94c-.54,0-.98,.44-.98,.98s.44,.98,.98,1.06h2.76v8.86h-3.07c-.56,0-1.02,.46-1.02,1.02s.46,1.02,1.02,1.02h3.07v9.54h-3.07c-.56,0-1.02,.46-1.02,1.02s.46,1.02,1.02,1.02h3.07v8.86h-3.07c-.56,0-1.02,.46-1.02,1.02s.46,1.02,1.02,1.02h3.07v8.86h-3.07c-.56,0-1.02,.46-1.02,1.02s.46,1.02,1.02,1.02h3.07v14.35s.63,.98,1.17,.98,.98-.44,.87-.98V13.98c.11-.54-.33-.98-.87-.98Z"/>
          <circle class="cls-4" cx="58.17" cy="30.03" r="3.41"/>
          <circle class="cls-4" cx="54.76" cy="53.2" r="3.41"/>
          <circle class="cls-4" cx="80.65" cy="53.2" r="3.41"/>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    device: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.g25-main-icon #dark, .g25-main-icon #light  {
  text-align: center;

  svg {
    height: 80px;
  }
}

#mobile {
  .cls-1 {
    fill: #fff;
  }

  .cls-2 {
    opacity: .51;
  }

  .cls-3 {
    opacity: .21;
  }

  .cls-4 {
    fill: #2d2d2d;
  }

  .cls-5 {
    fill: #fc8db0;
  }

  .cls-6 {
    fill: #ededed;
  }
}

#light {
  .cls-1 {
    fill: #ddd;
  }

  .cls-2 {
    fill: #828282;
  }
}

#dark {
  .cls-1 {
    opacity: .51;
  }

  .cls-2 {
    fill: #1d2328;
    stroke: #fc8db0;
    stroke-miterlimit: 10;
  }

  .cls-3 {
    fill: #f94b8a;
  }

  .cls-4 {
    fill: #fc8db0;
  }
}

.g25-menus-card-item:hover {
  #light {
    .cls-1 {
      fill: #f94b8a;
    }

    .cls-2 {
      fill: #2d2d2d;
    }

    .cls-3 {
      fill: #fc8db0;
    }
  }
  #dark {
    .cls-1 {
      opacity: 1;
    }
  }
}
</style>
