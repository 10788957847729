<template>
  <div class="dashboard mx-auto">
    <div class="" v-if="selectedOrderResult.orderResultStatus > 1">
      <div class="d-flex justify-content-start hidden-g25-dashboard-mobile">
        <div class="mr-4">
          <menu-supervised-models></menu-supervised-models>
        </div>
        <div class="mr-4">
          <menu-unsupervised-models></menu-unsupervised-models>
        </div>
        <div class="mr-4">
          <menu-diy-models> </menu-diy-models>
        </div>
      </div>

      <div class="d-flex justify-content-start mt-4 hidden-g25-dashboard-mobile">
        <div class="mr-4">
          <menu-genetice-distances></menu-genetice-distances>
        </div>
        <div class="mr-4">
          <menu-supplementary></menu-supplementary>
        </div>
      </div>
    </div>

    <div class="hidden-g25-dashboard-desktop" v-if="selectedOrderResult.orderResultStatus > 1">
      <menu-supervised-models></menu-supervised-models>
      <menu-unsupervised-models class="mt-2"/>
      <menu-genetice-distances class="mt-2"/>
      <menu-diy-models class="mt-2" />
      <menu-supplementary class="mt-2" />
    </div>

    <div v-if="selectedOrderResult.orderResultStatus <= 1">
      <no-result-page :orderResultStatus="selectedOrderResult.orderResultStatus"/>
    </div>
  </div>
</template>

<script>
import MenuSupervisedModels from './menus/MenuSupervisedModels.vue'
import MenuGeneticeDistances from './menus/MenuGeneticeDistances.vue'
import MenuDiyModels from './menus/MenuDiyModels.vue'
import MenuSupplementary from './menus/MenuSupplementary.vue'
import MenuUnsupervisedModels from './menus/MenuUnsupervisedModels.vue'
import NoResultPage from './NoResult/NoResultPage.vue'

export default {
  components: {
    MenuSupervisedModels,
    MenuGeneticeDistances,
    MenuDiyModels,
    MenuSupplementary,
    MenuUnsupervisedModels,
    NoResultPage,
  },
  data() {
    return {
      isLoaded: false,
    }
  },
  computed: {
    selectedOrderResult() {
      return this.$store.getters['orderResult/getSelectedOrderResult'];
    },
  },
}
</script>

<style lang="scss">
@import './dashboard-g25.scss';
</style>
<style scoped>
.dashboard  {
  max-width: 1100px !important;
}
</style>
