<template>
  <div class="g25-main-icon">
    <svg id="light" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105.5 104.17" v-if="variant == 'light' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-1" d="M54.84,45.83v26.12c0,.69-.35,1.36-.97,1.68-1.52,.78-3.03-.3-3.03-1.74v-26c0-1.44,1.51-2.52,3.03-1.74,.62,.32,.97,.99,.97,1.68Z"/>
          <circle class="cls-2" cx="52.75" cy="43.43" r="7.28"/>
          <path class="cls-4" d="M5.46,68.72H99.92c.82,0,1.58,.5,1.83,1.28,.45,1.41-.49,2.72-1.71,2.72H5.58c-.82,0-1.58-.5-1.83-1.28-.45-1.41,.49-2.72,1.71-2.72Z"/>
          <circle class="cls-3" cx="100.05" cy="70.71" r="5.46"/>
          <circle class="cls-3" cx="76.4" cy="70.71" r="5.46"/>
          <circle class="cls-3" cx="52.75" cy="70.71" r="5.46"/>
          <path class="cls-4" d="M30.84,69.48v26.12c0,.69-.35,1.36-.97,1.68-1.52,.78-3.03-.3-3.03-1.74v-26c0-1.44,1.51-2.52,3.03-1.74,.62,.32,.97,.99,.97,1.68Z"/>
          <circle class="cls-3" cx="29.1" cy="70.71" r="5.46"/>
          <circle class="cls-3" cx="29.1" cy="98.71" r="5.46"/>
          <circle class="cls-3" cx="5.46" cy="70.71" r="5.46"/>
          <path class="cls-4" d="M81.5,25.28c0-13.96-12.83-25.28-28.66-25.28S24.18,11.32,24.18,25.28c0,7.11,3.33,13.53,8.68,18.12,1.86,1.59,2.98,3.87,2.98,6.32v2.77c0,1.59,.95,3.1,2.47,3.59,2.48,.8,4.76-1.14,4.76-3.43,0-1.93,1.61-3.2,3.34-2.86,.05,0,2.43,.39,2.43,2.79v2.09c0,1.91,1.43,3.64,3.34,3.72,2.01,.09,3.66-1.51,3.66-3.5v-2.34c0-2.43,2.57-3,2.62-3.01,1.91-.37,3.67,.85,3.6,3.12-.05,1.56,.95,3.03,2.41,3.58,2.77,1.03,5.37-.99,5.37-3.62v-2.91c0-2.45,1.13-4.72,2.98-6.32,5.35-4.59,8.68-11.01,8.68-18.12Z"/>
          <path class="cls-1" d="M43.58,45.01c2.63-2.23,2.77-7.95,2.77-7.95l-5.26-2.06c-13.93-14.34-1.2-32.09-1.2-32.09-9.25,4.13-15.6,12.59-15.6,22.37,0,7.63,3.86,14.46,9.96,19.05,1,.75,1.6,1.92,1.6,3.17v6.05c0,.52,.21,1.02,.59,1.38,3.12,2.97,6.74,.62,6.74-2.29,0,0-2.21-5.4,.42-7.63Z"/>
          <g>
            <path class="cls-3" d="M46.85,35c0,3.12-2.53,5.65-5.65,5.65s-5.65-2.53-5.65-5.65,2.53-5.65,5.65-5.65,5.65,2.53,5.65,5.65Z"/>
            <path class="cls-3" d="M70.03,35c0,3.12-2.53,5.65-5.65,5.65s-5.65-2.53-5.65-5.65,2.53-5.65,5.65-5.65,5.65,2.53,5.65,5.65Z"/>
            <path class="cls-3" d="M56.05,43.17c0,1.8-1.46,3.26-3.26,3.26s-3.26-1.46-3.26-3.26,1.46-5,3.26-5,3.26,3.2,3.26,5Z"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="dark" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.5 104.67" v-if="variant == 'dark' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g class="cls-2">
          <path class="cls-5" d="M55.34,45.83v26.12c0,.69-.35,1.36-.97,1.68-1.52,.78-3.03-.3-3.03-1.74v-26c0-1.44,1.51-2.52,3.03-1.74,.62,.32,.97,.99,.97,1.68Z"/>
          <circle class="cls-1" cx="53.25" cy="43.43" r="7.28"/>
          <path class="cls-6" d="M5.96,68.72H100.42c.82,0,1.58,.5,1.83,1.28,.45,1.41-.49,2.72-1.71,2.72H6.08c-.82,0-1.58-.5-1.83-1.28-.45-1.41,.49-2.72,1.71-2.72Z"/>
          <circle class="cls-4" cx="100.55" cy="70.71" r="5.46"/>
          <circle class="cls-4" cx="76.9" cy="70.71" r="5.46"/>
          <circle class="cls-4" cx="53.25" cy="70.71" r="5.46"/>
          <path class="cls-6" d="M31.34,69.48v26.12c0,.69-.35,1.36-.97,1.68-1.52,.78-3.03-.3-3.03-1.74v-26c0-1.44,1.51-2.52,3.03-1.74,.62,.32,.97,.99,.97,1.68Z"/>
          <circle class="cls-4" cx="29.6" cy="70.71" r="5.46"/>
          <circle class="cls-4" cx="29.6" cy="98.71" r="5.46"/>
          <circle class="cls-4" cx="5.96" cy="70.71" r="5.46"/>
          <path class="cls-6" d="M82,25.28c0-13.96-12.83-25.28-28.66-25.28S24.68,11.32,24.68,25.28c0,7.11,3.33,13.53,8.68,18.12,1.86,1.59,2.98,3.87,2.98,6.32v2.77c0,1.59,.95,3.1,2.47,3.59,2.48,.8,4.76-1.14,4.76-3.43,0-1.93,1.61-3.2,3.34-2.86,.05,0,2.43,.39,2.43,2.79v2.09c0,1.91,1.43,3.64,3.34,3.72,2.01,.09,3.66-1.51,3.66-3.5v-2.34c0-2.43,2.57-3,2.62-3.01,1.91-.37,3.67,.85,3.6,3.12-.05,1.56,.95,3.03,2.41,3.58,2.77,1.03,5.37-.99,5.37-3.62v-2.91c0-2.45,1.13-4.72,2.98-6.32,5.35-4.59,8.68-11.01,8.68-18.12Z"/>
          <path class="cls-5" d="M44.08,45.01c2.63-2.23,2.77-7.95,2.77-7.95l-5.26-2.06c-13.93-14.34-1.2-32.09-1.2-32.09-9.25,4.13-15.6,12.59-15.6,22.37,0,7.63,3.86,14.46,9.96,19.05,1,.75,1.6,1.92,1.6,3.17v6.05c0,.52,.21,1.02,.59,1.38,3.12,2.97,6.74,.62,6.74-2.29,0,0-2.21-5.4,.42-7.63Z"/>
          <g>
            <path class="cls-3" d="M47.35,35c0,3.12-2.53,5.65-5.65,5.65s-5.65-2.53-5.65-5.65,2.53-5.65,5.65-5.65,5.65,2.53,5.65,5.65Z"/>
            <path class="cls-3" d="M70.53,35c0,3.12-2.53,5.65-5.65,5.65s-5.65-2.53-5.65-5.65,2.53-5.65,5.65-5.65,5.65,2.53,5.65,5.65Z"/>
            <path class="cls-3" d="M56.55,43.17c0,1.8-1.46,3.26-3.26,3.26s-3.26-1.46-3.26-3.26,1.46-5,3.26-5,3.26,3.2,3.26,5Z"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="hover" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105.5 104.17" v-if="variant == 'light' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-2" d="M54.84,45.83v26.12c0,.69-.35,1.36-.97,1.68-1.52,.78-3.03-.3-3.03-1.74v-26c0-1.44,1.51-2.52,3.03-1.74,.62,.32,.97,.99,.97,1.68Z"/>
          <circle class="cls-1" cx="52.75" cy="43.43" r="7.28"/>
          <path class="cls-4" d="M5.46,68.72H99.92c.82,0,1.58,.5,1.83,1.28,.45,1.41-.49,2.72-1.71,2.72H5.58c-.82,0-1.58-.5-1.83-1.28-.45-1.41,.49-2.72,1.71-2.72Z"/>
          <circle class="cls-3" cx="100.05" cy="70.71" r="5.46"/>
          <circle class="cls-3" cx="76.4" cy="70.71" r="5.46"/>
          <circle class="cls-3" cx="52.75" cy="70.71" r="5.46"/>
          <path class="cls-4" d="M30.84,69.48v26.12c0,.69-.35,1.36-.97,1.68-1.52,.78-3.03-.3-3.03-1.74v-26c0-1.44,1.51-2.52,3.03-1.74,.62,.32,.97,.99,.97,1.68Z"/>
          <circle class="cls-3" cx="29.1" cy="70.71" r="5.46"/>
          <circle class="cls-3" cx="29.1" cy="98.71" r="5.46"/>
          <circle class="cls-3" cx="5.46" cy="70.71" r="5.46"/>
          <path class="cls-4" d="M81.5,25.28c0-13.96-12.83-25.28-28.66-25.28S24.18,11.32,24.18,25.28c0,7.11,3.33,13.53,8.68,18.12,1.86,1.59,2.98,3.87,2.98,6.32v2.77c0,1.59,.95,3.1,2.47,3.59,2.48,.8,4.76-1.14,4.76-3.43,0-1.93,1.61-3.2,3.34-2.86,.05,0,2.43,.39,2.43,2.79v2.09c0,1.91,1.43,3.64,3.34,3.72,2.01,.09,3.66-1.51,3.66-3.5v-2.34c0-2.43,2.57-3,2.62-3.01,1.91-.37,3.67,.85,3.6,3.12-.05,1.56,.95,3.03,2.41,3.58,2.77,1.03,5.37-.99,5.37-3.62v-2.91c0-2.45,1.13-4.72,2.98-6.32,5.35-4.59,8.68-11.01,8.68-18.12Z"/>
          <path class="cls-2" d="M43.58,45.01c2.63-2.23,2.77-7.95,2.77-7.95l-5.26-2.06c-13.93-14.34-1.2-32.09-1.2-32.09-9.25,4.13-15.6,12.59-15.6,22.37,0,7.63,3.86,14.46,9.96,19.05,1,.75,1.6,1.92,1.6,3.17v6.05c0,.52,.21,1.02,.59,1.38,3.12,2.97,6.74,.62,6.74-2.29,0,0-2.21-5.4,.42-7.63Z"/>
          <g>
            <path class="cls-3" d="M46.85,35c0,3.12-2.53,5.65-5.65,5.65s-5.65-2.53-5.65-5.65,2.53-5.65,5.65-5.65,5.65,2.53,5.65,5.65Z"/>
            <path class="cls-3" d="M70.03,35c0,3.12-2.53,5.65-5.65,5.65s-5.65-2.53-5.65-5.65,2.53-5.65,5.65-5.65,5.65,2.53,5.65,5.65Z"/>
            <path class="cls-3" d="M56.05,43.17c0,1.8-1.46,3.26-3.26,3.26s-3.26-1.46-3.26-3.26,1.46-5,3.26-5,3.26,3.2,3.26,5Z"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="hover_dark" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.5 104.67" v-if="variant == 'dark' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-4" d="M55.34,45.83v26.12c0,.69-.35,1.36-.97,1.68-1.52,.78-3.03-.3-3.03-1.74v-26c0-1.44,1.51-2.52,3.03-1.74,.62,.32,.97,.99,.97,1.68Z"/>
          <circle class="cls-1" cx="53.25" cy="43.43" r="7.28"/>
          <path class="cls-5" d="M5.96,68.72H100.42c.82,0,1.58,.5,1.83,1.28,.45,1.41-.49,2.72-1.71,2.72H6.08c-.82,0-1.58-.5-1.83-1.28-.45-1.41,.49-2.72,1.71-2.72Z"/>
          <circle class="cls-3" cx="100.55" cy="70.71" r="5.46"/>
          <circle class="cls-3" cx="76.9" cy="70.71" r="5.46"/>
          <circle class="cls-3" cx="53.25" cy="70.71" r="5.46"/>
          <path class="cls-5" d="M31.34,69.48v26.12c0,.69-.35,1.36-.97,1.68-1.52,.78-3.03-.3-3.03-1.74v-26c0-1.44,1.51-2.52,3.03-1.74,.62,.32,.97,.99,.97,1.68Z"/>
          <circle class="cls-3" cx="29.6" cy="70.71" r="5.46"/>
          <circle class="cls-3" cx="29.6" cy="98.71" r="5.46"/>
          <circle class="cls-3" cx="5.96" cy="70.71" r="5.46"/>
          <path class="cls-5" d="M82,25.28c0-13.96-12.83-25.28-28.66-25.28S24.68,11.32,24.68,25.28c0,7.11,3.33,13.53,8.68,18.12,1.86,1.59,2.98,3.87,2.98,6.32v2.77c0,1.59,.95,3.1,2.47,3.59,2.48,.8,4.76-1.14,4.76-3.43,0-1.93,1.61-3.2,3.34-2.86,.05,0,2.43,.39,2.43,2.79v2.09c0,1.91,1.43,3.64,3.34,3.72,2.01,.09,3.66-1.51,3.66-3.5v-2.34c0-2.43,2.57-3,2.62-3.01,1.91-.37,3.67,.85,3.6,3.12-.05,1.56,.95,3.03,2.41,3.58,2.77,1.03,5.37-.99,5.37-3.62v-2.91c0-2.45,1.13-4.72,2.98-6.32,5.35-4.59,8.68-11.01,8.68-18.12Z"/>
          <path class="cls-4" d="M44.08,45.01c2.63-2.23,2.77-7.95,2.77-7.95l-5.26-2.06c-13.93-14.34-1.2-32.09-1.2-32.09-9.25,4.13-15.6,12.59-15.6,22.37,0,7.63,3.86,14.46,9.96,19.05,1,.75,1.6,1.92,1.6,3.17v6.05c0,.52,.21,1.02,.59,1.38,3.12,2.97,6.74,.62,6.74-2.29,0,0-2.21-5.4,.42-7.63Z"/>
          <g>
            <path class="cls-2" d="M47.35,35c0,3.12-2.53,5.65-5.65,5.65s-5.65-2.53-5.65-5.65,2.53-5.65,5.65-5.65,5.65,2.53,5.65,5.65Z"/>
            <path class="cls-2" d="M70.53,35c0,3.12-2.53,5.65-5.65,5.65s-5.65-2.53-5.65-5.65,2.53-5.65,5.65-5.65,5.65,2.53,5.65,5.65Z"/>
            <path class="cls-2" d="M56.55,43.17c0,1.8-1.46,3.26-3.26,3.26s-3.26-1.46-3.26-3.26,1.46-5,3.26-5,3.26,3.2,3.26,5Z"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="mobile" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139 139" v-if="device == 'MOBILE'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-6" d="M139,15V124c0,8.25-6.75,15-15,15H15c-8.25,0-15-6.75-15-15V15C0,10.93,1.65,7.21,4.31,4.51c.23-.24,.47-.47,.72-.68C7.68,1.45,11.19,0,15,0H124c8.25,0,15,6.75,15,15Z"/>
          <g class="cls-4">
            <polygon class="cls-2" points="134.81 133.91 123 122.09 123 61 124 61 124 121.68 135.52 133.2 134.81 133.91"/>
            <polygon class="cls-2" points="124 86 118 86 118 75 119 75 119 85 124 85 124 86"/>
            <polygon class="cls-2" points="124.11 76.63 123.47 75.86 129.34 70.97 130.3 67.13 131.27 67.37 130.23 71.53 124.11 76.63"/>
            <path class="cls-2" d="M131.29,98.25c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-2" d="M117.29,97.25c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-2" d="M118.29,76.25c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-2" d="M130.79,67.75c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-2" d="M123.61,61.75c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <polygon class="cls-2" points="123.4 113.1 117 106.71 117 97 118 97 118 106.29 124.1 112.4 123.4 113.1"/>
            <rect class="cls-2" x="122.25" y="100.26" width="9.37" height="1" transform="translate(-34.07 119.27) rotate(-45)"/>
          </g>
          <g class="cls-3">
            <path class="cls-2" d="M17,15.9v61.1h-1V16.32L4.14,4.45c.23-.24,.47-.47,.72-.68l12.14,12.13Z"/>
            <polygon class="cls-2" points="23 63 22 63 22 53 17 53 17 52 23 52 23 63"/>
            <polygon class="cls-2" points="10.27 71.04 9.3 70.79 10.34 66.63 16.46 61.53 17.1 62.3 11.23 67.19 10.27 71.04"/>
            <path class="cls-2" d="M9.28,44.91c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-2" d="M23.28,45.91c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-2" d="M22.28,66.91c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-2" d="M9.78,75.41c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <path class="cls-2" d="M16.96,81.41c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5Zm0-4c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"/>
            <polygon class="cls-2" points="24 41 23 41 23 31.71 16.9 25.6 17.6 24.9 24 31.29 24 41"/>
            <rect class="cls-2" x="8.95" y="36.9" width="9.37" height="1" transform="translate(-22.45 20.6) rotate(-45)"/>
          </g>
        </g>
        <g>
          <path class="cls-5" d="M71.25,54.87v18.28c0,.48-.25,.96-.68,1.18-1.06,.55-2.12-.21-2.12-1.22v-18.2c0-1.01,1.06-1.76,2.12-1.22,.43,.22,.68,.69,.68,1.18Z"/>
          <circle class="cls-1" cx="69.79" cy="53.19" r="5.09"/>
          <path class="cls-2" d="M36.69,70.89H102.81c.57,0,1.11,.35,1.28,.9,.31,.98-.34,1.9-1.19,1.9H36.77c-.57,0-1.11-.35-1.28-.9-.31-.98,.34-1.9,1.19-1.9Z"/>
          <circle class="cls-5" cx="102.89" cy="72.28" r="3.82"/>
          <circle class="cls-5" cx="86.34" cy="72.28" r="3.82"/>
          <circle class="cls-5" cx="69.79" cy="72.28" r="3.82"/>
          <path class="cls-2" d="M54.45,71.42v18.28c0,.48-.25,.96-.68,1.18-1.06,.55-2.12-.21-2.12-1.22v-18.2c0-1.01,1.06-1.76,2.12-1.22,.43,.22,.68,.69,.68,1.18Z"/>
          <circle class="cls-5" cx="53.24" cy="72.28" r="3.82"/>
          <circle class="cls-5" cx="53.24" cy="91.88" r="3.82"/>
          <circle class="cls-5" cx="36.69" cy="72.28" r="3.82"/>
          <path class="cls-2" d="M89.91,40.49c0-9.77-8.98-17.7-20.06-17.7s-20.06,7.92-20.06,17.7c0,4.97,2.33,9.47,6.07,12.68,1.3,1.11,2.09,2.71,2.09,4.42v1.94c0,1.11,.67,2.17,1.73,2.51,1.74,.56,3.33-.8,3.33-2.4,0-1.35,1.13-2.24,2.34-2,.03,0,1.7,.27,1.7,1.96v1.46c0,1.34,1,2.55,2.34,2.61,1.4,.06,2.56-1.06,2.56-2.45v-1.64c0-1.7,1.8-2.1,1.83-2.11,1.34-.26,2.57,.6,2.52,2.19-.04,1.09,.66,2.12,1.69,2.5,1.94,.72,3.76-.69,3.76-2.53v-2.04c0-1.71,.79-3.31,2.09-4.42,3.75-3.21,6.07-7.71,6.07-12.68Z"/>
          <path class="cls-2" d="M63.37,54.3c1.84-1.56,1.94-5.56,1.94-5.56l-3.68-1.44c-9.75-10.04-.84-22.46-.84-22.46-6.48,2.89-10.92,8.81-10.92,15.65,0,5.34,2.7,10.12,6.97,13.33,.7,.53,1.12,1.35,1.12,2.22v4.24c0,.36,.15,.71,.41,.96,2.18,2.08,4.71,.43,4.71-1.6,0,0-1.55-3.78,.29-5.34Z"/>
          <g>
            <path class="cls-5" d="M65.66,47.29c0,2.19-1.77,3.96-3.96,3.96s-3.96-1.77-3.96-3.96,1.77-3.96,3.96-3.96,3.96,1.77,3.96,3.96Z"/>
            <path class="cls-5" d="M81.88,47.29c0,2.19-1.77,3.96-3.96,3.96s-3.96-1.77-3.96-3.96,1.77-3.96,3.96-3.96,3.96,1.77,3.96,3.96Z"/>
            <path class="cls-5" d="M72.1,53.01c0,1.26-1.02,2.28-2.28,2.28s-2.28-1.02-2.28-2.28,1.02-3.5,2.28-3.5,2.28,2.24,2.28,3.5Z"/>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    variant: {
      type: String,
      required: true,
    },
    device: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
  #mobile {
    .cls-1 {
      fill: #f0788c;
    }

    .cls-2 {
      fill: #fff;
    }

    .cls-3 {
      opacity: .51;
    }

    .cls-4 {
      opacity: .21;
    }

    .cls-5 {
      fill: #2d2d2d;
    }

    .cls-6 {
      fill: #67d2e1;
    }
  }

  #light {
    .cls-1 {
      fill: #ddd;
    }

    .cls-2 {
      fill: #f0788c;
    }

    .cls-3 {
      fill: #828282;
    }

    .cls-4 {
      fill: #f2f2f2;
    }
  }

  #dark {
    .cls-1 {
      fill: #f0788c;
    }

    .cls-2 {
      opacity: .51;
    }

    .cls-3, .cls-4 {
      fill: #1d2328;
    }

    .cls-5 {
      fill: #48c2cc;
    }

    .cls-4 {
      stroke: #67d2e1;
      stroke-miterlimit: 10;
    }

    .cls-6 {
      fill: #67d2e1;
    }
  }

  #hover {
    display: none;

    .cls-1 {
      fill: #f0788c;
    }

    .cls-2 {
      fill: #48c2cc;
    }

    .cls-3 {
      fill: #2d2d2d;
    }

    .cls-4 {
      fill: #67d2e1;
    }
  }

  #hover_dark {
    display: none;
    .cls-1 {
      fill: #f0788c;
    }

    .cls-2, .cls-3 {
      fill: #1d2328;
    }

    .cls-4 {
      fill: #48c2cc;
    }

    .cls-3 {
      stroke: #67d2e1;
      stroke-miterlimit: 10;
    }

    .cls-5 {
      fill: #67d2e1;
    }
  }

  .g25-menus-card-item:hover {
    #hover, #hover_dark {
      display: block;
    }
    #light, #dark {
      display: none;
    }
  }
</style>
