<template>
  <div class="g25-main-icon">
    <svg id="light" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 80"  v-if="variant == 'light' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-1" d="M0,48.3v9.69c0,1.37,1.38,2.5,2.84,2.26l3.27-.61c.33,1.04,.76,2.07,1.23,3.01-1.75,1.22-3.6,2.07-3.75,3.67-.05,.71,.14,1.37,.62,1.84l6.92,6.83c.95,.99,2.75,.8,3.6-.38l1.9-2.73c.95,.52,2,.94,3.04,1.27-.38,2.12-1.09,3.96-.05,5.22,.43,.52,1.09,.85,1.71,.85,5.35,0,7.79,.14,8.91-.05,1.86-.29,.05-1.44,1.86-5.5,.12-.27,.26-.56,.41-.86,.35-.68,.76-1.31,1.22-1.9,1.65-2.14,3.96-3.66,6.59-4.35,1.05-.28,2.15-.42,3.29-.42,1.23,0,1.23-1.88,0-1.88-.6,0-1.15-.03-1.66-.09-4.64-.54-5.67-3.22-6.83-2.07-.28,.28-.57,.54-.88,.79-8,6.61-20.7,1.02-20.7-9.73,0-10.45,12.1-16.23,20.3-10.03,.05,.03,.09,.07,.13,.1,.38,.28,.9,.28,1.24-.05,1.5-1.34,2.91-2.07,4.16-2.48,3.09-1.03,5.18-.11,5.18-1.65,0-.27-.12-.52-.3-.69-.01-.01-.03-.03-.04-.03-.75-.04-1.49-.15-2.2-.33,0,0-.01,0-.02,0-2.41-.31-4.68-1.29-6.51-2.84-.52-.44-1-.92-1.45-1.44-.38-.44-.88-.41-1.24-.16l1.64,1.6,4,3.88c-2.24-.84-4.21-2.18-5.76-4.02-.03-.03-.05-.05-.07-.08-.43-.5-.22-1.09,.18-1.38l-.42-.41c.56-.31,.59-.66,.37-1.22-.3-.79-1.08-2-1.42-4.06-.09-.47-.47-.8-.95-.8h-9.05c-1.38,0-2.52,1.37-2.23,2.82l.57,3.25c-1.04,.33-2.04,.75-3.04,1.27-1.23-1.78-2.09-3.58-3.69-3.72-.71-.09-1.38,.14-1.8,.61l-6.92,6.83c-.99,.99-.81,2.78,.43,3.62l2.7,1.88c-.47,.94-.9,1.94-1.23,2.97-2.13-.38-4.03-1.09-5.31,0C.28,47.03,0,47.69,0,48.3Z"/>
          <g>
            <path class="cls-2" d="M33.62,34.96c-2.25,0-2.25-3.38,0-3.38h23.2c2.25,0,2.25,3.38,0,3.38h-23.2Zm.07-15.76h23.02c2.27,0,2.27,3.38,0,3.38h-23.02c-2.22,0-2.22-3.38,0-3.38Z"/>
            <path class="cls-2" d="M36.42,57.48h20.04c2.27,0,2.27,3.38,0,3.38h-20.04c-2.27,0-2.27-3.38,0-3.38Zm-1.13-12.39h21.13c2.22,0,2.22,3.38,0,3.38h-21.13c-2.22,0-2.22-3.38,0-3.38Z"/>
            <path class="cls-2" d="M33.68,5.68h23.02c2.27,0,2.27,3.38,0,3.38h-23.02c-2.22,0-2.22-3.38,0-3.38Z"/>
            <path class="cls-2" d="M33.49,70.99h23.44c1.7,0,1.7,3.38,0,3.38h-23.44c-1.7,0-1.7-3.38,0-3.38Z"/>
            <path class="cls-3" d="M30.43,78.35c0,.31,.07,.59,.19,.81,.71,1.35,3.21,1.08,3.21-.81,0-1.44,.28-2.82,.8-4.08,.51-1.26,1.24-2.4,2.16-3.37,2.1-2.26,5.12-3.66,8.43-3.66,2.48,0,4.84-.61,6.91-1.68,4.66-2.42,7.88-7.21,7.88-12.76,0-5.58-3.21-10.37-7.88-12.79-2.07-1.09-4.43-1.69-6.91-1.69-.2,0-.41,0-.61-.01-.75-.04-1.49-.15-2.2-.33,0,0-.01,0-.02,0-1.97-.49-3.74-1.48-5.16-2.84-.99-.95-1.81-2.08-2.4-3.33-.65-1.38-1-2.92-1-4.54s.34-3.06,.94-4.41c.57-1.27,1.36-2.41,2.34-3.38,2.08-2.06,4.97-3.33,8.11-3.33,2.48,0,4.83-.61,6.9-1.69,4.66-2.41,7.88-7.2,7.88-12.75,0-2.25-3.4-2.25-3.4,0,0,1.56-.33,3.03-.92,4.36-.56,1.27-1.36,2.41-2.33,3.37-2.08,2.07-4.98,3.33-8.13,3.33-2.48,0-4.83,.61-6.9,1.69-2.48,1.28-4.56,3.24-5.95,5.64,0,0-.01,.02-.02,.03-.05,.09-.1,.18-.15,.27t0,.01c-1.09,1.98-1.72,4.24-1.75,6.66,0,.07,0,.13,0,.2,0,2.57,.68,4.97,1.89,7.05,.03,.05,.06,.1,.09,.16,0,0,0,.02,.02,.03,1.39,2.34,3.43,4.26,5.87,5.52,.47,.25,.95,.47,1.46,.66,1.69,.66,3.54,1.03,5.45,1.03,3.21,0,6.14,1.32,8.23,3.46,.95,.97,1.73,2.12,2.27,3.38,.57,1.31,.88,2.75,.88,4.26s-.38,3.28-1.07,4.69c-.61,1.27-1.48,2.42-2.52,3.37-2.05,1.86-4.81,3-7.78,3-.98,0-1.94,.1-2.88,.28-1.42,.27-2.78,.75-4.03,1.41-4.66,2.41-7.88,7.21-7.88,12.79Z"/>
            <path class="cls-1" d="M30.43,1.69c0-2.25,3.4-2.25,3.4,0,0,6.19,5.2,11.07,11.38,11.07,8.08,0,14.78,6.42,14.78,14.49s-6.71,14.44-14.78,14.44c-6.19,0-11.38,4.92-11.38,11.11,0,6.19,5.2,11.07,11.38,11.07,8.08,0,14.78,6.42,14.78,14.49,0,2.2-3.4,2.2-3.4,0,0-6.19-5.2-11.11-11.38-11.11-8.08,0-14.78-6.42-14.78-14.44s6.71-14.49,14.78-14.49c6.19,0,11.38-4.88,11.38-11.07s-5.2-11.11-11.38-11.11c-8.08,0-14.78-6.42-14.78-14.44Z"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="dark" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 80" v-if="variant == 'dark' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g class="cls-1">
          <path class="cls-4" d="M1.5,48.19v9.69c0,1.37,1.39,2.5,2.86,2.26l3.29-.61c.33,1.04,.76,2.07,1.24,3.01-1.76,1.22-3.62,2.07-3.77,3.67-.05,.71,.15,1.37,.62,1.84l6.96,6.83c.95,.99,2.77,.8,3.63-.38l1.91-2.73c.95,.52,2.01,.94,3.05,1.27-.38,2.12-1.1,3.96-.05,5.22,.43,.52,1.09,.85,1.72,.85,5.39,0,7.84,.14,8.97-.05,1.87-.29,.05-1.44,1.88-5.5,.12-.27,.26-.56,.42-.86,.35-.68,.76-1.31,1.23-1.9,1.66-2.14,3.99-3.66,6.63-4.35,1.06-.28,2.17-.42,3.31-.42,1.24,0,1.24-1.88,0-1.88-.6,0-1.16-.03-1.67-.09-4.67-.54-5.71-3.22-6.87-2.07-.29,.28-.58,.54-.88,.79-8.05,6.61-20.83,1.02-20.83-9.73,0-10.45,12.18-16.23,20.43-10.03,.05,.03,.09,.07,.13,.1,.38,.28,.91,.28,1.25-.05,1.51-1.34,2.93-2.07,4.18-2.48,3.11-1.03,5.22-.11,5.22-1.65,0-.27-.12-.52-.3-.69-.01-.01-.03-.03-.04-.03-.76-.04-1.5-.15-2.21-.33,0,0-.01,0-.02,0-2.43-.31-4.71-1.29-6.55-2.84-.52-.44-1.01-.92-1.46-1.44-.38-.44-.89-.41-1.25-.16l1.65,1.6,4.03,3.88c-2.25-.84-4.23-2.18-5.79-4.02-.03-.03-.05-.05-.07-.08-.44-.5-.23-1.09,.19-1.38l-.42-.41c.56-.31,.59-.66,.37-1.22-.3-.79-1.09-2-1.42-4.06-.09-.47-.48-.8-.95-.8h-9.11c-1.38,0-2.53,1.37-2.24,2.82l.57,3.25c-1.05,.33-2.05,.75-3.05,1.27-1.24-1.78-2.1-3.58-3.72-3.72-.72-.09-1.38,.14-1.82,.61l-6.96,6.83c-1,.99-.82,2.78,.43,3.62l2.72,1.88c-.48,.94-.91,1.94-1.24,2.97-2.15-.38-4.06-1.09-5.35,0-.52,.42-.81,1.09-.81,1.69Z"/>
          <g>
            <path class="cls-3" d="M35.34,35.12c-2.19,0-2.19-3.26,0-3.26h22.55c2.19,0,2.19,3.26,0,3.26h-22.55Zm.06-15.5h22.38c2.21,0,2.21,3.26,0,3.26h-22.38c-2.16,0-2.16-3.26,0-3.26Z"/>
            <path class="cls-3" d="M38.11,57.15h19.48c2.21,0,2.21,3.26,0,3.26h-19.48c-2.21,0-2.21-3.26,0-3.26Zm-1.12-12.24h20.54c2.16,0,2.16,3.26,0,3.26h-20.54c-2.16,0-2.16-3.26,0-3.26Z"/>
            <path class="cls-3" d="M35.41,5.75h22.38c2.21,0,2.21,3.26,0,3.26h-22.38c-2.16,0-2.16-3.26,0-3.26Z"/>
            <path class="cls-3" d="M35.22,71.02h22.79c1.65,0,1.65,3.26,0,3.26h-22.79c-1.65,0-1.65-3.26,0-3.26Z"/>
            <path class="cls-5" d="M31.75,78.35c0,.31,.07,.59,.19,.81,.72,1.35,3.23,1.08,3.23-.81,0-1.44,.28-2.82,.8-4.08,.51-1.26,1.25-2.4,2.17-3.37,2.11-2.26,5.16-3.66,8.48-3.66,2.5,0,4.87-.61,6.95-1.68,4.69-2.42,7.93-7.21,7.93-12.76,0-5.58-3.23-10.37-7.93-12.79-2.08-1.09-4.45-1.69-6.95-1.69-.21,0-.41,0-.62-.01-.76-.04-1.5-.15-2.21-.33,0,0-.01,0-.02,0-1.98-.49-3.76-1.48-5.19-2.84-1-.95-1.82-2.08-2.41-3.33-.65-1.38-1.01-2.92-1.01-4.54,0-1.58,.34-3.06,.94-4.41,.57-1.27,1.37-2.41,2.36-3.38,2.09-2.06,5-3.33,8.16-3.33,2.5,0,4.86-.61,6.94-1.69,4.69-2.41,7.93-7.2,7.93-12.75,0-2.25-3.43-2.25-3.43,0,0,1.56-.33,3.03-.92,4.36-.56,1.27-1.37,2.41-2.35,3.37-2.09,2.07-5.01,3.33-8.18,3.33-2.5,0-4.86,.61-6.95,1.69-2.5,1.28-4.59,3.24-5.99,5.64,0,0-.01,.02-.02,.03-.05,.09-.1,.18-.15,.27t0,.01c-1.09,1.98-1.73,4.24-1.76,6.66,0,.07,0,.13,0,.2,0,2.57,.69,4.97,1.9,7.05,.03,.05,.06,.1,.09,.16,0,0,0,.02,.02,.03,1.4,2.34,3.45,4.26,5.91,5.52,.47,.25,.96,.47,1.46,.66,1.7,.66,3.56,1.03,5.49,1.03,3.23,0,6.18,1.32,8.28,3.46,.96,.97,1.74,2.12,2.29,3.38,.57,1.31,.88,2.75,.88,4.26s-.38,3.28-1.08,4.69c-.62,1.27-1.48,2.42-2.54,3.37-2.07,1.86-4.84,3-7.83,3-.99,0-1.95,.1-2.9,.28-1.43,.27-2.8,.75-4.06,1.41-4.69,2.41-7.93,7.21-7.93,12.79Z"/>
            <path class="cls-2" d="M31.74,1.69c0-2.25,3.42-2.25,3.42,0,0,6.19,5.23,11.07,11.45,11.07,8.13,0,14.88,6.42,14.88,14.49s-6.75,14.44-14.88,14.44c-6.23,0-11.45,4.92-11.45,11.11,0,6.19,5.23,11.07,11.45,11.07,8.13,0,14.88,6.42,14.88,14.49,0,2.2-3.42,2.2-3.42,0,0-6.19-5.23-11.11-11.45-11.11-8.13,0-14.88-6.42-14.88-14.44s6.75-14.49,14.88-14.49c6.23,0,11.45-4.88,11.45-11.07s-5.23-11.11-11.45-11.11c-8.13,0-14.88-6.42-14.88-14.44Z"/>
          </g>
        </g>
      </g>
    </svg>

    <svg id="mobile" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139 139" v-if="device == 'MOBILE'">
      <defs>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-8" d="M0,15V124c0,8.25,6.75,15,15,15H124c8.25,0,15-6.75,15-15V15c0-4.07-1.65-7.79-4.31-10.49-.23-.24-.47-.47-.72-.68C131.32,1.45,127.81,0,124,0H15C6.75,0,0,6.75,0,15Z"/>
          <g class="cls-7">
            <polygon class="cls-1" points="4.19 133.91 16 122.09 16 61 15 61 15 121.68 3.48 133.2 4.19 133.91"/>
            <polygon class="cls-1" points="16 86 22 86 22 76 21 76 21 85 16 85 16 86"/>
            <polygon class="cls-1" points="15.47 76.63 16.11 75.86 10.23 70.97 9.27 67.13 8.3 67.37 9.34 71.53 15.47 76.63"/>
            <path class="cls-1" d="M5.79,95.75c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <path class="cls-1" d="M19.79,94.75c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-1" d="M18.79,73.75c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-1" d="M6.29,65.25c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <path class="cls-1" d="M13.47,59.25c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <polygon class="cls-1" points="16.6 113.1 23 106.71 23 97 22 97 22 106.29 15.9 112.4 16.6 113.1"/>
            <rect class="cls-1" x="12.15" y="96.08" width="1" height="9.37" transform="translate(-67.54 38.46) rotate(-45)"/>
          </g>
          <g class="cls-3">
            <path class="cls-1" d="M122,15.9v61.1s1,0,1,0V16.32s11.86-11.87,11.86-11.87c-.23-.24-.47-.47-.72-.68l-12.14,12.13Z"/>
            <polygon class="cls-1" points="117 62 118 62 118 53 123 53 123 52 117 52 117 62"/>
            <polygon class="cls-1" points="129.31 71.04 130.28 70.79 129.24 66.63 123.12 61.53 122.48 62.3 128.35 67.19 129.31 71.04"/>
            <path class="cls-1" d="M127.8,42.41c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-1" d="M113.8,43.41c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5Z"/>
            <path class="cls-1" d="M114.8,64.41c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-1" d="M127.3,72.91c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <path class="cls-1" d="M120.12,78.91c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5Zm1,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5Z"/>
            <polygon class="cls-1" points="116 41 117 41 117 31.71 123.1 25.6 122.4 24.9 116 31.29 116 41"/>
            <rect class="cls-1" x="125.44" y="32.71" width="1" height="9.37" transform="translate(10.44 100.01) rotate(-45)"/>
          </g>
        </g>
        <g>
          <path class="cls-4" d="M42.96,63.73v7.4c0,1.04,1.04,1.91,2.15,1.73l2.48-.47c.25,.79,.57,1.58,.93,2.3-1.33,.93-2.73,1.58-2.84,2.8-.03,.54,.11,1.04,.47,1.4l5.24,5.21c.72,.75,2.08,.61,2.73-.29l1.44-2.08c.72,.4,1.51,.72,2.3,.97-.29,1.62-.83,3.02-.03,3.99,.32,.39,.82,.65,1.29,.65,4.06,0,5.9,.1,6.75-.03,1.41-.22,.04-1.1,1.41-4.2,.09-.21,.19-.42,.31-.65,.26-.52,.57-1,.92-1.45,1.25-1.64,3-2.79,4.99-3.32,.8-.21,1.63-.32,2.49-.32,.93,0,.93-1.44,0-1.44-.45,0-.87-.02-1.26-.07-3.51-.41-4.29-2.46-5.17-1.58-.21,.21-.43,.41-.66,.6-6.06,5.04-15.68,.78-15.68-7.43,0-7.97,9.17-12.38,15.38-7.65,.03,.02,.06,.05,.1,.07,.29,.21,.68,.21,.94-.04,1.14-1.02,2.2-1.58,3.15-1.9,2.34-.78,3.93-.08,3.93-1.26,0-.2-.09-.39-.23-.52,0,0-.02-.02-.03-.02-.57-.03-1.13-.11-1.67-.25,0,0,0,0-.01,0-1.83-.23-3.54-.99-4.93-2.16-.39-.33-.76-.7-1.1-1.1-.28-.33-.67-.31-.94-.12l1.24,1.22,3.03,2.96c-1.7-.64-3.19-1.66-4.36-3.07-.02-.02-.04-.04-.05-.06-.33-.38-.17-.83,.14-1.05l-.32-.31c.42-.23,.44-.5,.28-.93-.22-.6-.82-1.53-1.07-3.1-.07-.36-.36-.61-.72-.61h-6.86c-1.04,0-1.91,1.04-1.69,2.15l.43,2.48c-.79,.25-1.55,.57-2.3,.97-.93-1.36-1.58-2.73-2.8-2.84-.54-.07-1.04,.11-1.37,.47l-5.24,5.21c-.75,.75-.61,2.12,.32,2.76l2.04,1.44c-.36,.72-.68,1.48-.93,2.26-1.62-.29-3.05-.83-4.03,0-.39,.32-.61,.83-.61,1.29Z"/>
          <g>
            <path class="cls-2" d="M68.43,53.76c-1.65,0-1.65-2.49,0-2.49h16.97c1.65,0,1.65,2.49,0,2.49h-16.97Zm.05-11.83h16.84c1.66,0,1.66,2.49,0,2.49h-16.84c-1.63,0-1.63-2.49,0-2.49Z"/>
            <path class="cls-2" d="M70.52,70.57h14.66c1.66,0,1.66,2.49,0,2.49h-14.66c-1.66,0-1.66-2.49,0-2.49Zm-.84-9.34h15.46c1.63,0,1.63,2.49,0,2.49h-15.46c-1.63,0-1.63-2.49,0-2.49Z"/>
            <path class="cls-2" d="M68.48,31.35h16.84c1.66,0,1.66,2.49,0,2.49h-16.84c-1.63,0-1.63-2.49,0-2.49Z"/>
            <path class="cls-2" d="M68.34,81.15h17.15c1.25,0,1.25,2.49,0,2.49h-17.15c-1.25,0-1.25-2.49,0-2.49Z"/>
            <path class="cls-6" d="M65.72,86.74c0,.24,.05,.45,.14,.62,.54,1.03,2.43,.82,2.43-.62,0-1.1,.21-2.15,.6-3.11,.38-.96,.94-1.83,1.64-2.57,1.59-1.72,3.88-2.79,6.38-2.79,1.88,0,3.67-.46,5.23-1.28,3.53-1.85,5.97-5.5,5.97-9.74s-2.43-7.92-5.97-9.76c-1.57-.83-3.35-1.29-5.23-1.29-.15,0-.31,0-.46,0-.57-.03-1.13-.11-1.67-.25,0,0,0,0-.01,0-1.49-.37-2.83-1.13-3.91-2.16-.75-.73-1.37-1.59-1.82-2.54-.49-1.05-.76-2.22-.76-3.47,0-1.2,.25-2.33,.71-3.36,.43-.97,1.03-1.84,1.78-2.58,1.58-1.57,3.76-2.54,6.14-2.54,1.88,0,3.66-.46,5.23-1.29,3.53-1.84,5.97-5.5,5.97-9.73,0-1.72-2.58-1.72-2.58,0,0,1.19-.25,2.31-.69,3.33-.42,.97-1.03,1.84-1.77,2.57-1.58,1.58-3.77,2.54-6.16,2.54-1.88,0-3.66,.46-5.23,1.29-1.88,.98-3.45,2.47-4.51,4.3,0,0,0,.01-.01,.02-.04,.07-.07,.13-.11,.2t0,0c-.82,1.51-1.3,3.24-1.32,5.08,0,.05,0,.1,0,.15,0,1.96,.52,3.8,1.43,5.38,.02,.04,.04,.08,.07,.12,0,0,0,.01,.01,.02,1.05,1.79,2.6,3.25,4.45,4.21,.35,.19,.72,.36,1.1,.5,1.28,.5,2.68,.78,4.13,.78,2.43,0,4.65,1.01,6.23,2.64,.72,.74,1.31,1.62,1.72,2.58,.43,1,.66,2.09,.66,3.25,0,1.29-.29,2.5-.81,3.58-.46,.97-1.12,1.85-1.91,2.57-1.56,1.42-3.64,2.29-5.9,2.29-.74,0-1.47,.07-2.18,.21-1.08,.21-2.1,.57-3.05,1.08-3.53,1.84-5.97,5.5-5.97,9.76Z"/>
            <path class="cls-5" d="M65.72,28.25c0-1.72,2.58-1.72,2.58,0,0,4.72,3.93,8.44,8.62,8.44,6.12,0,11.2,4.9,11.2,11.05s-5.08,11.02-11.2,11.02c-4.69,0-8.62,3.76-8.62,8.48s3.93,8.44,8.62,8.44c6.12,0,11.2,4.9,11.2,11.05,0,1.68-2.58,1.68-2.58,0,0-4.72-3.93-8.48-8.62-8.48-6.12,0-11.2-4.9-11.2-11.02s5.08-11.05,11.2-11.05c4.69,0,8.62-3.72,8.62-8.44s-3.93-8.48-8.62-8.48c-6.12,0-11.2-4.9-11.2-11.02Z"/>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    device: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.g25-main-icon #light, .g25-main-icon #dark  {
  height: 80px;
}

#mobile {
  .cls-1, .cls-2 {
    fill: #fff;
  }

  .cls-3 {
    opacity: .51;
  }

  .cls-2, .cls-4, .cls-5, .cls-6 {
    fill-rule: evenodd;
  }

  .cls-4 {
    fill: #2d2d2d;
  }

  .cls-5 {
    fill: #ddd;
  }

  .cls-7 {
    opacity: .21;
  }

  .cls-6 {
    fill: #ededed;
  }

  .cls-8 {
    fill: #c494b1;
  }
}

#light {
  .cls-1 {
    fill: #828282;
  }

  .cls-1, .cls-2, .cls-3 {
    fill-rule: evenodd;
  }

  .cls-2 {
    fill: #ddd;
  }

  .cls-3 {
    fill: #cecece;
  }
}

#dark {
  .cls-1 {
    opacity: .51;
  }

  .cls-2 {
    fill: #904c6e;
  }

  .cls-2, .cls-3, .cls-4, .cls-5 {
    fill-rule: evenodd;
  }

  .cls-3 {
    fill: #c494b1;
  }

  .cls-4 {
    fill: #1d2328;
    stroke: #c494b1;
    stroke-miterlimit: 10;
    stroke-width: 3px;
  }

  .cls-5 {
    fill: #a87390;
  }
}

.g25-menus-card-item:hover {
  #light {
      .cls-1 {
        fill: #904c6e;
      }

      .cls-1, .cls-2, .cls-3 {
        fill-rule: evenodd;
      }

      .cls-2 {
        fill: #c494b1;
      }

      .cls-3 {
        fill: #a87390;
      }
  }
  #dark {
    .cls-1 {
      opacity: 1;
      fill: #904c6e;
    }

    .cls-2 {
      fill: #904c6e;
    }

    .cls-2, .cls-3, .cls-4, .cls-5 {
      fill-rule: evenodd;
    }

    .cls-3 {
      fill: #c494b1;
    }

    .cls-4 {
      fill: #1d2328;
      stroke: #c494b1;
      stroke-miterlimit: 10;
      stroke-width: 3px;
    }

    .cls-5 {
      fill: #a87390;
    }
  }
}
</style>
